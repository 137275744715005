import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import {withStyles, TextField, Paper, MenuItem, LinearProgress} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import './SearchBox.css';

function renderInput(inputProps) {
  const {InputProps, classes, ref, ...other} = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
        },
        ...InputProps,
      }}
      {...other}
      fullWidth
    />
  );
}

function renderSuggestion({suggestion, index, itemProps, highlightedIndex, selectedItem, id, filledHandler}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <div onClick={() => filledHandler(suggestion)} key={suggestion.id}>
      <MenuItem
        {...itemProps}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.label}
      </MenuItem>
    </div>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({label: PropTypes.string}).isRequired
};

function getSuggestions(value, array) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : array.filter(suggestion => {
      const keep =
        count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});


function SearchBox(props) {
  const {classes, array, selectChangeHandler, className, filledHandler, isLoading, icon} = props;

  return (
    <div className={className}>
      <SearchIcon style={{position: 'relative', top: '7px', left: '-2px', color: 'grey', display: icon}}/>
      <Downshift id="downshift-simple">
        {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem
          }) => (
          <div className={classes.container}>
            {renderInput({
              classes,
              InputProps: getInputProps({
                placeholder: props.placeholder,
                onSelect: selectChangeHandler,
              }),
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(inputValue, array).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({item: suggestion.label}),
                      highlightedIndex,
                      selectedItem,
                      filledHandler,
                      id: suggestion.id
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
            <LinearProgress className="searchbox-progressbar" style={{display: isLoading ? 'block' : 'none'}}/>
          </div>
        )}
      </Downshift>
      <div className={classes.divider}/>
    </div>
  );
}

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  array: PropTypes.array.isRequired,
  selectChangeHandler: PropTypes.func.isRequired,
  filledHandler: PropTypes.func.isRequired
};

export default withStyles(styles)(SearchBox);