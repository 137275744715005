import axios from 'axios';

let baseUrl = process.env.REACT_APP_BASEURL;
const protocol = window.location.protocol;

export let apiURL = baseUrl;

switch (window.location.hostname) {
  case 'es-filestore.e-zhetysu.kz':
    apiURL = `${protocol}//es-filestore-api.e-zhetysu.kz/public/`;
    break;
  case 'es-filestore-test.e-zhetysu.kz':
    apiURL = `${protocol}//es-filestore-api-test.e-zhetysu.kz/public/`;
    break;
  case 'nobd-filestore.iac.kz':
    apiURL = `${protocol}//nobd-filestore-api.iac.kz/public/`;
    break;
  case 'nobd-filestore-test.iac.kz':
    apiURL = `${protocol}//nobd-filestore-api-test.iac.kz/public/`;
    break;
  case 'es-filestore-test.iac.kz':
    apiURL = `${protocol}//es-filestore-api-test.iac.kz/public/`;
    break;
  case 'es-filestore.iac.kz':
    apiURL = `${protocol}//es-filestore-api.iac.kz/public/`;
    break;
  case 'filestore.gu.almobl.kz':
    apiURL = `${protocol}//filestore-api.gu.almobl.kz/public/`;
    break;
  case '192.168.9.154':
    apiURL = `${protocol}//192.168.9.154:8011/public/`;
    break;
  default:
    apiURL = window.location.origin;
}

const instance = axios.create({
  baseURL: apiURL
});

export default instance;
