import React, {Component, Fragment} from 'react';
import {NotificationManager} from 'react-notifications';
import {connect} from "react-redux";
import Select from 'react-select';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames';
import {Button, TextField, Checkbox, FormControl, FormControlLabel} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import {LinearProgress} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {emphasize} from '@material-ui/core/styles/colorManipulator';
import MaterialSelect from '@material-ui/core/Select';
import {translate} from "../../localization/i18n";
import DataTable from "../../components/DataTable/DataTable";
import {searchCatalogs, searchDocTypes, searchDocuments, resetSearchDocuments, deleteDocument} from "../../store/actions/search";
import {fetchCatalogs} from "../../store/actions/catalogManagement";
import {fetchDocTypes} from "../../store/actions/docTypeManagement";
import ConfirmationWindow from "../../components/ConfirmationWindow/ConfirmationWindow";
import {maybeParseJSONString, calendarToDate, isEmptyValue} from "../../components/Utils";
import {startOrJustOrEmptyValue, endOrJustOrEmptyValue, makeEmptyValue, makeJustValue, makeRangeValue, makeOrUpdateStartRangeValue, makeOrUpdateEndRangeValue} from "../../components/Utils";
import './Search.css';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {translate('search.noMessage')}
    </Typography>
  );
}

function inputComponent({inputRef, ...props}) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      onChange={event => props.selectProps.onType(event.target.value)}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const makeConstraintItem = (constraint, key) =>
  <MenuItem
    key={key}
    value={constraint}
  >
    {translate(`search.op.${constraint}`)}
  </MenuItem>;

const makeConstraintsSelector = (props, constraints) => {
  const {value, onChange} = props;

  return (
    <MaterialSelect
      value={value}
      onChange={onChange}
      inputProps={{id: `${props.type}-helper`}}
      input={
        <OutlinedInput
          name={`${props.type}-helper`}
          id={`${props.type}-helper`}
          labelWidth={100}
        />
      }
    >
      {constraints.map(makeConstraintItem)}
    </MaterialSelect>
  );
};

const selectConstraintsSelector = props => {
  switch (props.type) {
    case 'text':
      return makeConstraintsSelector(props, ["EXACT", "CONTAINS"]);
    case 'boolean':
      return makeConstraintsSelector(props, ["EXACT"]);
    case 'number':
    case 'date':
      return makeConstraintsSelector(props,
        [
          "GREATER_OR_EQUALS",
          "LESS_OR_EQUALS",
          "GREATER",
          "LESS",
          "EXACT",
          "BETWEEN"
        ]);
    default:
      return null;
  }
};

const MetaFieldConstraints = props =>
  <FormControl
    variant="outlined"
    margin="dense"
    required
    fullWidth
  >
    <InputLabel
      htmlFor={`${props.type}-helper`}
      shrink={true}
      style={{background: '#fff'}}
    >
      {translate('search.constraint')}
    </InputLabel>
    {selectConstraintsSelector(props)}
  </FormControl>;

MetaFieldConstraints.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const MetaField = props => {
  const {kkey, index, metaLang, meta} = props;
  const {
    changeMetaFieldValue, changeMetaFieldRecurrentValue,
    changeCheckBoxMetaField, changeCheckBoxMetaFieldRecurrent,
    changeSelectSearchConstraints, changeSelectSearchConstraintsRecurrent
  } = props;

  return (
    meta.fieldType.dataType === 'boolean' ?
      maybeParseJSONString(meta.fieldType.recurrent) ?
        <Fragment>
          <FormControlLabel
            control={
              <Checkbox
                onChange={event => changeCheckBoxMetaFieldRecurrent(event, kkey, index)}
                color="primary"
                name="required"
                required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
              />
            }
            label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
          />
          <MetaFieldConstraints
            type={meta.fieldType.dataType}
            value={meta.operation}
            onChange={event => changeSelectSearchConstraintsRecurrent(event, kkey, index)}
          />
        </Fragment>
        :
        <Fragment>
          <FormControlLabel
            control={
              <Checkbox
                onChange={event => changeCheckBoxMetaField(event, kkey, index)}
                color="primary"
                name="required"
                required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
              />
            }
            label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
          />
          <MetaFieldConstraints
            type={meta.fieldType.dataType}
            value={meta.operation}
            onChange={event => changeSelectSearchConstraints(event, kkey, index)}
          />
        </Fragment>
      :
      maybeParseJSONString(meta.fieldType.recurrent) ?
        <Fragment>
          <TextField
            margin="dense"
            variant="outlined"
            name="fieldValue"
            type={meta.fieldType.dataType}
            label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
            value={startOrJustOrEmptyValue(meta.fieldValue[0])}
            required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
            onChange={
              event =>
                changeMetaFieldRecurrentValue(event, kkey, index,
                  meta.operation === "BETWEEN" ? makeOrUpdateStartRangeValue(meta.fieldValue[0]) : makeJustValue)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          {meta.operation === "BETWEEN" &&
          <TextField
            margin="dense"
            variant="outlined"
            name="fieldValue"
            type={meta.fieldType.dataType}
            label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
            value={endOrJustOrEmptyValue(meta.fieldValue[0])}
            required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
            onChange={
              event =>
                changeMetaFieldRecurrentValue(event, kkey, index,
                  meta.operation === "BETWEEN" ? makeOrUpdateEndRangeValue(meta.fieldValue[0]) : makeJustValue)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />}
          <MetaFieldConstraints
            type={meta.fieldType.dataType}
            value={meta.operation}
            onChange={event => changeSelectSearchConstraintsRecurrent(event, kkey, index)}
          />
        </Fragment>
        :
        <Fragment>
          <TextField
            margin="dense"
            variant="outlined"
            name="fieldValue"
            type={meta.fieldType.dataType}
            label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
            value={startOrJustOrEmptyValue(meta.fieldValue)}
            required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
            onChange={
              event =>
                changeMetaFieldValue(event, kkey, index,
                  meta.operation === "BETWEEN" ? makeOrUpdateStartRangeValue(meta.fieldValue) : makeJustValue)
            }
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          {meta.operation === "BETWEEN" &&
          <TextField
            margin="dense"
            variant="outlined"
            name="fieldValue"
            type={meta.fieldType.dataType}
            label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
            value={endOrJustOrEmptyValue(meta.fieldValue)}
            required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
            onChange={
              event =>
                changeMetaFieldValue(event, kkey, index,
                  meta.operation === "BETWEEN" ? makeOrUpdateEndRangeValue(meta.fieldValue) : makeJustValue)
            }
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />}
          <MetaFieldConstraints
            type={meta.fieldType.dataType}
            value={meta.operation}
            onChange={event => changeSelectSearchConstraints(event, kkey, index)}
          />
        </Fragment>
  );
};

MetaField.propTypes = {
  kkey: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  metaLang: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  changeMetaFieldValue: PropTypes.func.isRequired,
  changeMetaFieldRecurrentValue: PropTypes.func.isRequired,
  changeCheckBoxMetaField: PropTypes.func.isRequired,
  changeCheckBoxMetaFieldRecurrent: PropTypes.func.isRequired,
  changeSelectSearchConstraints: PropTypes.func.isRequired,
  changeSelectSearchConstraintsRecurrent: PropTypes.func.isRequired,
};

const MetaFields = ({
                      index, metaLang, metaFields,
                      changeMetaFieldValue, changeMetaFieldRecurrentValue,
                      changeCheckBoxMetaField, changeCheckBoxMetaFieldRecurrent,
                      changeSelectSearchConstraints, changeSelectSearchConstraintsRecurrent
                    }) =>
  metaFields.map((meta, key) =>
    <div
      key={key}
      className="search_meta-section-container">
      <MetaField
        kkey={key}
        index={index}
        metaLang={metaLang}
        meta={meta}
        changeMetaFieldValue={changeMetaFieldValue}
        changeMetaFieldRecurrentValue={changeMetaFieldRecurrentValue}
        changeCheckBoxMetaField={changeCheckBoxMetaField}
        changeCheckBoxMetaFieldRecurrent={changeCheckBoxMetaFieldRecurrent}
        changeSelectSearchConstraints={changeSelectSearchConstraints}
        changeSelectSearchConstraintsRecurrent={changeSelectSearchConstraintsRecurrent}
      />
    </div>);

MetaFields.propTypes = {
  index: PropTypes.number.isRequired,
  metaLang: PropTypes.string.isRequired,
  metaFields: PropTypes.array.isRequired,
  changeMetaFieldValue: PropTypes.func.isRequired,
  changeMetaFieldRecurrentValue: PropTypes.func.isRequired,
  changeCheckBoxMetaField: PropTypes.func.isRequired,
  changeCheckBoxMetaFieldRecurrent: PropTypes.func.isRequired,
  changeSelectSearchConstraints: PropTypes.func.isRequired,
  changeSelectSearchConstraintsRecurrent: PropTypes.func.isRequired,
};

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCatalogs: [],
      selectedDocTypes: [],
      filename: '',
      dateFrom: '',
      dateTo: '',
      isOpen: false,
      typesMetaFields: [],
      formData: {},
      curPage: 1,
    };
  }

  componentDidMount() {
    this.props.fetchCatalogs(1);
    this.props.fetchDocTypes(1);
  }

  componentWillUnmount() {
    this.props.resetSearchDocuments();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }

    const {selectedDocTypes} = this.state;

    if (selectedDocTypes.length !== prevState.selectedDocTypes.length) {
      const typesMetaFields = selectedDocTypes.map(docType => Search.addMetaFieldsValues(docType.typesMetaFields));
      this.setState({typesMetaFields});
    }

    if (prevState.curPage !== this.state.curPage || prevState.formData !== this.state.formData) {
      this.props.searchDocuments(this.state.curPage, this.state.formData);
    }
  }

  handleMultiSelectionChange = name => value => {
    this.props.resetSearchDocuments();

    this.setState({[name]: value});
  };

  inputChangeHandler = (key, value) => {
    this.setState({[key]: value});
  };

  onCatalogSearch = (e) => {
    if (!e || e.length >= 3) {
      this.props.searchCatalogs(e);
    }
  };

  onDocTypeSearch = (e) => {
    if (!e || e.length >= 3) {
      this.props.searchDocTypes(e);
    }
  };

  viewDocument = (dirID, typeID, id) => {
    this.props.history.push(`/documents_list/${dirID}/type/${typeID}/view_document/${id}`);
  };

  editDocument = (dirID, typeID, id) => {
    this.props.history.push(`/documents_list/${dirID}/type/${typeID}/edit_document/${id}`);
  };

  deleteDocument = (dirID, docID) => {
    this.setState({isOpen: true, dirID, docID});
  };

  deleteDoc = () => {
    this.props.deleteDoc(this.state.dirID, this.state.docID, this.state.curPage, this.state.formData);
    this.setState({isOpen: false});
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  submitFormHandler = event => {
    event.preventDefault();

    const {selectedCatalogs, selectedDocTypes, typesMetaFields} = this.state;

    if (selectedCatalogs.length < 1 || selectedDocTypes < 1) {
      NotificationManager.error(translate('search.requiredData'));

      return;
    }

    const typesFields = typesMetaFields.map(fields =>
      fields
        .filter(field => {
          if (Array.isArray(field.fieldValue)) {
            return !isEmptyValue(field.fieldValue[0]);
          } else {
            return !isEmptyValue(field.fieldValue);
          }
        })
        .map(field => {
          let fieldValue;

          if (Array.isArray(field.fieldValue)) {
            fieldValue = field.fieldValue[0];
          } else {
            fieldValue = field.fieldValue;
          }

          if (field.operation === "BETWEEN") {
            if (field.fieldType.dataType === 'date') {
              fieldValue = `${calendarToDate(startOrJustOrEmptyValue(fieldValue))}*${calendarToDate(endOrJustOrEmptyValue(fieldValue))}`;
            } else {
              fieldValue = `${startOrJustOrEmptyValue(fieldValue)}*${endOrJustOrEmptyValue(fieldValue)}`;
            }
          } else {
            fieldValue = startOrJustOrEmptyValue(fieldValue);

            if (field.fieldType.dataType === 'date') {
              fieldValue = calendarToDate(fieldValue);
            }
          }

          return {
            fieldValue,
            fieldCode: field.fieldCode,
            operation: field.operation,
          };
        })
    );

    const formData = {
      directories: selectedCatalogs.map(({value}) => value),
      documentTypes: selectedDocTypes.map(({value}) => value),
      fileName: this.state.filename,
      fields: [].concat.apply([], typesFields),
    };

    if (this.state.dateFrom) {
      formData.dateFrom = calendarToDate(this.state.dateFrom);
    }

    if (this.state.dateTo) {
      formData.dateTo = calendarToDate(this.state.dateTo);
    }

    this.setState({formData});
  };

  changeMetaFieldValue = (event, key, index, consValue) => {
    const typesMetaFields = cloneDeep(this.state.typesMetaFields);
    typesMetaFields[index][key].fieldValue = consValue(event.target.value);
    this.setState({typesMetaFields});
  };

  changeMetaFieldRecurrentValue = (event, key, index, consValue) => {
    const typesMetaFields = cloneDeep(this.state.typesMetaFields);
    typesMetaFields[index][key].fieldValue[0] = consValue(event.target.value);
    this.setState({typesMetaFields});
  };

  changeCheckBoxMetaField = (event, key, index) => {
    const typesMetaFields = cloneDeep(this.state.typesMetaFields);
    const prevValue = startOrJustOrEmptyValue(typesMetaFields[index][key].fieldValue);
    typesMetaFields[index][key].fieldValue = makeJustValue(!prevValue);
    this.setState({typesMetaFields});
  };

  changeCheckBoxMetaFieldRecurrent = (event, key, index) => {
    const typesMetaFields = cloneDeep(this.state.typesMetaFields);
    const prevValue = startOrJustOrEmptyValue(typesMetaFields[index][key].fieldValue[0]);
    typesMetaFields[index][key].fieldValue[0] = makeJustValue(!prevValue);
    this.setState({typesMetaFields});
  };

  updateValue = (operation, value) => {
    const rawValue = startOrJustOrEmptyValue(value);
    return operation === "BETWEEN" ? makeRangeValue(rawValue) : makeJustValue(rawValue);
  };

  changeSelectSearchConstraints = (event, key, index) => {
    const typesMetaFields = cloneDeep(this.state.typesMetaFields);
    typesMetaFields[index][key].operation = event.target.value;
    typesMetaFields[index][key].fieldValue =
      this.updateValue(typesMetaFields[index][key].operation, typesMetaFields[index][key].fieldValue);
    this.setState({typesMetaFields});
  };

  changeSelectSearchConstraintsRecurrent = (event, key, index) => {
    const typesMetaFields = cloneDeep(this.state.typesMetaFields);
    typesMetaFields[index][key].operation = event.target.value;
    typesMetaFields[index][key].fieldValue[0] =
      this.updateValue(typesMetaFields[index][key].operation, typesMetaFields[index][key].fieldValue[0]);
    this.setState({typesMetaFields});
  };

  selectTranslation(kz, ru) {
    switch (this.props.lang) {
      case 'KZ_kz':
        return kz;
      default:
        return ru;
    }
  }

  makeSuggestions(options) {
    return options.map(obj => {
      const selectLang = () => this.selectTranslation(obj.kkName, obj.ruName);

      return {
        value: obj.id,
        label: selectLang(),
        dynLabel: selectLang,
        typesMetaFields: obj.fieldsMeta || [],
      };
    });
  }

  static orList(list1, list2) {
    if (list1.length > 0) return list1;
    if (list2.length > 0) return list2;
    return [];
  }

  static addMetaFieldsValues(metaFields) {
    return metaFields.map(meta => {
      if (maybeParseJSONString(meta.fieldType.recurrent)) {
        return {...meta, fieldValue: [makeEmptyValue()], operation: "EXACT"};
      } else {
        return {...meta, fieldValue: makeEmptyValue(), operation: "EXACT"};
      }
    });
  }

  onPageSwitch = curPage => {
    this.setState({curPage});
  };

  render() {
    const {classes, theme, lang} = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    let metaLang;
    if (lang === 'RU_ru') {
      metaLang = 'ruFieldName';
    } else {
      metaLang = 'kkFieldName';
    }

    const {catalogs, isCatalogsLoading, docTypes, isDocTypesLoading} = this.props;
    const {foundCatalogs, foundDocTypes} = this.props;

    const catalogSuggestions = this.makeSuggestions(Search.orList(foundCatalogs, catalogs));
    const docTypeSuggestions = this.makeSuggestions(Search.orList(foundDocTypes, docTypes));

    const {foundDocs, isSearchingDocs} = this.props;
    const {selectedCatalogs, selectedDocTypes} = this.state;

    const catalogNameById = id =>
      selectedCatalogs.filter(({value}) => value === id)[0].dynLabel;

    const docTypeNameById = id =>
      selectedDocTypes.filter(({value}) => value === id)[0].dynLabel;

    const docs = foundDocs.map(doc => ({
      id: doc.id,
      cols: [
        {value: doc.fileName, cb: () => this.viewDocument(doc.directoryId, doc.documentType, doc.id)},
        {value: catalogNameById(doc.directoryId)},
        {value: docTypeNameById(doc.documentType)},
        {
          value: () => translate('search.edit'),
          cb: () => this.editDocument(doc.directoryId, doc.documentType, doc.id)
        },
        {
          value: () => translate('search.delete'),
          cb: () => this.deleteDocument(doc.directoryId, doc.id)
        },
      ]
    }));

    return (
      <Fragment>
        <h3>{translate('search.title')}</h3>
        {(isCatalogsLoading || isDocTypesLoading)
          ?
          <LinearProgress className="preLoader"/>
          :
          <form
            className="search_form-container"
            autoComplete="off"
            onSubmit={this.submitFormHandler}>
            <Select
              classes={classes}
              textFieldProps={{
                label: translate('search.catalog'),
                InputLabelProps: {
                  shrink: true,
                },
              }}
              styles={selectStyles}
              options={catalogSuggestions}
              variant="outline"
              components={components}
              value={selectedCatalogs}
              onChange={this.handleMultiSelectionChange('selectedCatalogs')}
              onType={this.onCatalogSearch}
              placeholder={translate('search.catalog')}
              isMulti
            />
            <Select
              classes={classes}
              textFieldProps={{
                label: translate('search.docType'),
                InputLabelProps: {
                  shrink: true,
                },
              }}
              styles={selectStyles}
              options={docTypeSuggestions}
              variant="outline"
              components={components}
              value={selectedDocTypes}
              onChange={this.handleMultiSelectionChange('selectedDocTypes')}
              onType={this.onDocTypeSearch}
              placeholder={translate('search.docType')}
              isMulti
            />
            <TextField
              label={translate('search.filename')}
              margin="dense"
              variant="outlined"
              name="filename"
              className="search_form-container_filename-input"
              value={this.state.filename}
              onChange={event => this.inputChangeHandler('filename', event.target.value)}
              fullWidth
            />
            <div className="search">
              <TextField
                variant="outlined"
                margin="dense"
                label={translate('search.dateFrom')}
                type="date"
                value={this.state.dateFrom}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => this.inputChangeHandler('dateFrom', event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="dense"
                label={translate('search.dateTo')}
                type="date"
                value={this.state.dateTo}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => this.inputChangeHandler('dateTo', event.target.value)}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{display: 'block', clear: 'both'}}
              className="search_submit-btn">{translate('search.find')}
            </Button>
          </form>
        }
        {
          (selectedDocTypes.length === 1 && this.state.typesMetaFields.length > 0) &&
          <div>
            <h4 className="search_meta-title">{translate('search.metaTitle')}:</h4>
            <hr/>
            {this.state.typesMetaFields.map((meta, index) =>
              <MetaFields
                key={index}
                index={index}
                metaLang={metaLang}
                metaFields={meta}
                changeMetaFieldValue={this.changeMetaFieldValue}
                changeMetaFieldRecurrentValue={this.changeMetaFieldRecurrentValue}
                changeCheckBoxMetaField={this.changeCheckBoxMetaField}
                changeCheckBoxMetaFieldRecurrent={this.changeCheckBoxMetaFieldRecurrent}
                changeSelectSearchConstraints={this.changeSelectSearchConstraints}
                changeSelectSearchConstraintsRecurrent={this.changeSelectSearchConstraintsRecurrent}
              />)}
          </div>
        }
        <h4 className="search_meta-title">{translate('search.resultsTitle')}:</h4>
        <hr/>
        <DataTable
          onPageSwitch={this.onPageSwitch}
          curPage={this.state.curPage}
          numPages={this.props.pagesCount}
          rowsPerPage={this.props.pageSize}
          header={[
            {id: "filename", label: translate('search.colFilename')},
            {id: "catalog", label: translate('search.colCatalog')},
            {id: "doc_type", label: translate('search.colDocType')},
            {id: null},
            {id: null},
          ]}
          rows={docs}
          isLoading={isSearchingDocs}
          orderBy='filename'
        />
        <ConfirmationWindow
          isOpen={this.state.isOpen}
          handleClose={this.handleClose}
          delete={this.deleteDoc}
        />
      </Fragment>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  catalogs: state.catalogManagement.catalogs,
  isCatalogsLoading: state.catalogManagement.isLoading,
  docTypes: state.docTypeManagement.docTypes,
  isDocTypesLoading: state.docTypeManagement.isLoading,
  foundCatalogs: state.search.foundCatalogs,
  isSearchingCatalogs: state.search.isSearchingCatalogs,
  foundDocTypes: state.search.foundDocTypes,
  isSearchingDocTypes: state.search.isSearchingDocTypes,
  pagesCount: state.search.pagesCount,
  pageSize: state.search.pageSize,
  foundDocs: state.search.foundDocs,
  isSearchingDocs: state.search.isSearchingDocs,
});

const mapDispatchToProps = dispatch => ({
  fetchCatalogs: page => dispatch(fetchCatalogs(page)),
  fetchDocTypes: page => dispatch(fetchDocTypes(page)),
  searchCatalogs: word => dispatch(searchCatalogs(word)),
  searchDocTypes: word => dispatch(searchDocTypes(word)),
  searchDocuments: (page, data) => dispatch(searchDocuments(page, data)),
  resetSearchDocuments: () => dispatch(resetSearchDocuments()),
  deleteDoc: (dirID, docID, page, data) => dispatch(deleteDocument(dirID, docID, page, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(Search));
