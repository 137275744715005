import React from 'react';
import {Dialog, DialogTitle, DialogActions, Button} from '@material-ui/core';
import {translate} from "../../localization/i18n";

const ConfirmationWindow = props => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{translate('documentTypeManagement.confirm')}</DialogTitle>
      <DialogActions style={{padding: '10px'}}>
        <Button onClick={props.handleClose} variant="contained" color="primary" size="small">
          {translate('documentTypeManagement.cancelConfirm')}
        </Button>
        <Button onClick={props.delete} variant="contained" color="secondary" size="small" autoFocus>
          {translate('documentTypeManagement.deleteConfirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ConfirmationWindow;