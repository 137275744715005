import {
  SEARCH_BY_WORD, SEARCH_BY_WORD_FAILURE, SEARCH_BY_WORD_SUCCESS,
  SEARCH_CATALOGS, SEARCH_CATALOGS_SUCCESS, SEARCH_CATALOGS_FAILURE,
  SEARCH_DOCUMENT_TYPES, SEARCH_DOCUMENT_TYPES_SUCCESS, SEARCH_DOCUMENT_TYPES_FAILURE,
  SEARCH_DOCS, SEARCH_DOCS_SUCCESS, SEARCH_DOCS_FAILURE, RESET_SEARCH_DOCUMENTS,
} from "../actionTypes";

const initialState = {
  pagesCount: 0,
  pageSize: 0,
  isLoading: false,
  result: [],
  isSearchingCatalogs: false,
  foundCatalogs: [],
  isSearchingDocTypes: false,
  foundDocTypes: [],
  isSearchingDocs: false,
  foundDocs: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_BY_WORD:
      return {...state, isLoading: true, error: null, pagesCount: 0, pageSize: 0, result: []};
    case SEARCH_BY_WORD_SUCCESS:
      return {...state, isLoading: false, pagesCount: action.pagesCount, pageSize: action.pageSize, result: action.result};
    case SEARCH_BY_WORD_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case SEARCH_CATALOGS:
      return {...state, isSearchingCatalogs: true, error: null, foundCatalogs: []};
    case SEARCH_CATALOGS_SUCCESS:
      return {...state, isSearchingCatalogs: false, foundCatalogs: action.foundCatalogs};
    case SEARCH_CATALOGS_FAILURE:
      return {...state, isSearchingCatalogs: false, error: action.error};

    case SEARCH_DOCUMENT_TYPES:
      return {...state, isSearchingDocTypes: true, error: null, foundDocTypes: []};
    case SEARCH_DOCUMENT_TYPES_SUCCESS:
      return {...state, isSearchingDocTypes: false, foundDocTypes: action.foundDocTypes};
    case SEARCH_DOCUMENT_TYPES_FAILURE:
      return {...state, isSearchingDocTypes: false, error: action.error};

    case SEARCH_DOCS:
      return {...state, isSearchingDocs: true, error: null, pagesCount: 0, pageSize: 0, foundDocs: []};
    case SEARCH_DOCS_SUCCESS:
      return {...state, isSearchingDocs: false, pagesCount: action.pagesCount, pageSize: action.pageSize, foundDocs: action.foundDocs};
    case SEARCH_DOCS_FAILURE:
      return {...state, isSearchingDocs: false, error: action.error};

    case RESET_SEARCH_DOCUMENTS:
      return {...state, isSearchingDocs: false, error: null, pagesCount: 0, pageSize: 0, foundDocs: []};

    default:
      return state;
  }
};

export default reducer;
