export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const AUTH_LOGIN = 'AUTH/AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH/AUTH_LOGIN_FAILURE';
export const AUTH_LOGOUT = 'AUTH/AUTH_LOGOUT';

export const FETCH_CATALOGS = 'CATALOG_MANAGEMENT/FETCH_CATALOGS';
export const FETCH_CATALOGS_SUCCESS = 'CATALOG_MANAGEMENT/FETCH_CATALOGS_SUCCESS';
export const FETCH_CATALOGS_FAILURE = 'CATALOG_MANAGEMENT/FETCH_CATALOGS_FAILURE';

export const DELETE_CATALOG = 'CATALOG_MANAGEMENT/DELETE_CATALOG';
export const DELETE_CATALOG_SUCCESS = 'CATALOG_MANAGEMENT/DELETE_CATALOG_SUCCESS';
export const DELETE_CATALOG_FAILURE = 'CATALOG_MANAGEMENT/DELETE_CATALOG_FAILURE';

export const ADD_CATALOG = 'NEW_CATALOG/ADD_CATALOG';
export const ADD_CATALOG_SUCCESS = 'NEW_CATALOG/ADD_CATALOG_SUCCESS';
export const ADD_CATALOG_FAILURE = 'NEW_CATALOG/ADD_CATALOG_FAILURE';

export const CHANGE_CATALOG = 'CATALOG_MANAGEMENT/CHANGE_CATALOG';
export const CHANGE_CATALOG_SUCCESS = 'CATALOG_MANAGEMENT/CHANGE_CATALOG_SUCCESS';
export const CHANGE_CATALOG_FAILURE = 'CATALOG_MANAGEMENT/CHANGE_CATALOG_FAILURE';

export const FETCH_CATALOG = 'VIEW_CATALOG/FETCH_CATALOG';
export const FETCH_CATALOG_SUCCESS = 'VIEW_CATALOG_SUCCESS/FETCH_CATALOG_SUCCESS';
export const FETCH_CATALOG_FAILURE = 'VIEW_CATALOG_FAILURE/FETCH_CATALOG_FAILURE';

export const FETCH_DOCUMENT_TYPES = 'DOC_TYPE_MANAGEMENT/FETCH_DOCUMENT_TYPES';
export const FETCH_DOCUMENT_TYPES_SUCCESS = 'DOC_TYPE_MANAGEMENT/FETCH_DOCUMENT_TYPES_SUCCESS';
export const FETCH_DOCUMENTS_TYPE_FAILURE = 'DOC_TYPE_MANAGEMENT/FETCH_DOCUMENT_TYPES_FAILURE';

export const DELETE_DOCUMENT_TYPE = 'DOC_TYPE_MANAGEMENT/DELETE_DOCUMENT_TYPE';
export const DELETE_DOCUMENT_TYPE_SUCCESS = 'DOC_TYPE_MANAGEMENT/DELETE_DOCUMENT_TYPE_SUCCESS';
export const DELETE_DOCUMENT_TYPE_FAILURE = 'DOC_TYPE_MANAGEMENT/DELETE_DOCUMENT_TYPE_FAILURE';

export const ADD_DOCUMENT_TYPE = 'NEW_DOCUMENTS_TYPE/ADD_DOCUMENT_TYPE';
export const ADD_DOCUMENT_TYPE_SUCCESS = 'NEW_DOCUMENTS_TYPE/ADD_DOCUMENT_TYPE_SUCCESS';
export const ADD_DOCUMENT_TYPE_FAILURE = 'NEW_DOCUMENTS_TYPE/ADD_DOCUMENT_TYPE_FAILURE';

export const CHANGE_DOCUMENT_TYPE = 'DOC_TYPE_MANAGEMENT/CHANGE_DOCUMENT_TYPE';
export const CHANGE_DOCUMENT_TYPE_SUCCESS = 'DOC_TYPE_MANAGEMENT/CHANGE_DOCUMENT_TYPE_SUCCESS';
export const CHANGE_DOCUMENT_TYPE_FAILURE = 'DOC_TYPE_MANAGEMENT/CHANGE_DOCUMENT_TYPE_FAILURE';

export const FETCH_DOCUMENT_TYPE = 'VIEW_DOCUMENT_TYPE/FETCH_DOCUMENT_TYPE';
export const FETCH_DOCUMENT_TYPE_SUCCESS = 'VIEW_DOCUMENT_TYPE_SUCCESS/FETCH_DOCUMENT_TYPE_SUCCESS';
export const FETCH_DOCUMENT_TYPE_FAILURE = 'VIEW_DOCUMENT_TYPE_FAILURE/FETCH_DOCUMENT_TYPE_FAILURE';

export const ADD_DOCUMENT = 'NEW_DOCUMENT/ADD_DOCUMENT';
export const ADD_DOCUMENT_SUCCESS = 'NEW_DOCUMENT/ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAILURE = 'NEW_DOCUMENT/ADD_DOCUMENT_FAILURE';

export const SEARCH_DOCUMENTS_START = 'WORK_WITH_DOCUMENTS/SEARCH_DOCUMENTS_START';
export const SEARCH_DOCUMENTS_SUCCESS = 'WORK_WITH_DOCUMENTS/SEARCH_DOCUMENTS_SUCCESS';
export const SEARCH_DOCUMENTS_FAILURE = 'WORK_WITH_DOCUMENTS/SEARCH_DOCUMENTS_FAILURE';

export const DELETE_DOCUMENT_START = 'WORK_WITH_DOCUMENTS/DELETE_DOCUMENT_START';
export const DELETE_DOCUMENT_SUCCESS = 'WORK_WITH_DOCUMENTS/DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'WORK_WITH_DOCUMENTS/DELETE_DOCUMENT_FAILURE';

export const RESET_SEARCH_DOCS = 'WORK_WITH_DOCUMENTS/RESET_SEARCH_DOCS';

export const SEARCH_BY_WORD = 'SEARCH_BY_WORD';
export const SEARCH_BY_WORD_SUCCESS = 'SEARCH_BY_WORD_SUCCESS';
export const SEARCH_BY_WORD_FAILURE = 'SEARCH_BY_WORD_FAILURE';

export const SEARCH_CATALOGS = 'SEARCH/SEARCH_CATALOGS';
export const SEARCH_CATALOGS_SUCCESS = 'SEARCH/SEARCH_CATALOGS_SUCCESS';
export const SEARCH_CATALOGS_FAILURE = 'SEARCH/SEARCH_CATALOGS_FAILURE';

export const SEARCH_DOCUMENT_TYPES = 'SEARCH/SEARCH_DOCUMENT_TYPES';
export const SEARCH_DOCUMENT_TYPES_SUCCESS = 'SEARCH/SEARCH_DOCUMENT_TYPES_SUCCESS';
export const SEARCH_DOCUMENT_TYPES_FAILURE = 'SEARCH/SEARCH_DOCUMENT_TYPES_FAILURE';

export const SEARCH_DOCS = 'SEARCH/SEARCH_DOCUMENTS';
export const SEARCH_DOCS_SUCCESS = 'SEARCH/SEARCH_DOCUMENTS_SUCCESS';
export const SEARCH_DOCS_FAILURE = 'SEARCH/SEARCH_DOCUMENTS_FAILURE';

export const FETCH_DOCUMENT_START = 'WORK_WITH_DOCS/FETCH_DOCUMENT';
export const FETCH_DOCUMENT_SUCCESS = 'WORK_WITH_DOCS/FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_FAILURE = 'WORK_WITH_DOCS/FETCH_DOCUMENT_FAILURE';
export const RESET_DOCUMENT = 'WORK_WITH_DOCS/RESET_DOCUMENT';

export const RESET_SEARCH_DOCUMENTS = 'SEARCH/RESET_SEARCH_DOCUMENTS';

export const UPDATE_AUTH_STATE = 'LOGIN/UPDATE_AUTH_STATE';
export const UPDATE_TOKEN_FAILURE = 'LOGIN/UPDATE_TOKEN_FAILURE';

export const RESET_CATALOG = 'CATALOG_MANAGEMENT/RESET_CATALOG';
export const RESET_DOC_TYPE = 'DOC_TYPE_MANAGEMENT/RESET_DOC_TYPE';