export const KZ_kz = {
  translation: {
    login: {
      title: 'Авторизациялау',
      inputLogin: 'Кіру',
      inputPassword: 'Құпия сөз',
      button: 'Жүйеге кіріңіз',
      successMsg: 'Кіру сәтті өтті',
      errorMsg: 'Авторизация қатесі',
      logout: 'Шығу',
      updateToken: 'Токенді жаңарту',
      tokenFetched: 'Токен жаңартылды. Сіз жұмысты жалғастыра аласыз',
      updateTokenFailure: 'Токенді жаңарту мүмкін болмады, қайта іске қосылуға тырысыңыз'
    },

    layout: {
      catalogManagement: 'Каталогты басқару',
      docTypeManagement: 'Құжат түрін басқару',
      workWithDocs: 'Құжаттармен жұмыс істеу',
      search: 'Іздеу'
    },

    catalogManagement: {
      button: 'Жаңа каталог',
      title: 'Каталогты басқару',
      edit: 'өңдеу',
      delete: 'жою',
      start: 'басына',
      previous: 'алдыңғы',
      next: 'келесі',
      end: 'соңына',
      searchBoxPlaceholder: 'Каталогты іздеу',
      fetchFailed: 'Каталогтар алынбады',
      deletedSuccess: 'Каталог жойылды!',
      deletedFailure: 'Каталогты жою мүмкін болмады',
      confirm: 'Әрекетті растау',
      deleteConfirm: 'Жою',
      cancelConfirm: 'Болдырмау',
    },

    newCatalog: {
      title: 'Каталогтарды басқару / Жаңа каталог',
      kkName: 'Қазақша атауы',
      ruName: 'Орысша атауы',
      enName: 'Ағылшын тілі атауы',
      kkDescription: 'Сипаттама қазақ тілінде',
      ruDescription: 'Сипаттама орыс тілінде',
      enDescription: 'Сипаттама ағылшын тілінде',
      create: 'Жасау',
      successMessage: 'Жаңа каталог құрылды',
      errorMessage: 'Жаңа каталог жасалмады'
    },

    editCatalog: {
      title: 'Құжат түрін өңдеу',
      errorMessage: 'Каталогты өзгерту мүмкін емес',
      saveChanges: 'Өзгерістерді сақтаңыз',
      successMessage: 'Өзгерістер сақталды',
      cantSave: 'Өзгерістерді сақтау мүмкін болмады',
    },

    viewCatalog: {
      title: 'Каталогты қарау'
    },

    documentTypeManagement: {
      button: 'Жаңа құжат түрі',
      title: 'Құжат түрін басқару',
      edit: 'өңдеу',
      delete: 'жою',
      start: 'басына',
      previous: 'алдыңғы',
      next: 'келесі',
      end: 'соңына',
      searchBoxPlaceholder: 'Құжат түрін іздеу',
      fetchFailed: 'Құжат түрлерін алу мүмкін болмады',
      deletedSuccess: 'Құжат түрі жойылды!',
      deletedFailure: 'Құжат түрін жою сәтсіз аяқталды',
      confirm: 'Әрекетті растау',
      deleteConfirm: 'Жою',
      cancelConfirm: 'Болдырмау'
    },

    newDocType: {
      title: 'Құжат түрін басқару / Жаңа құжат түрі',
      kkName: 'Қазақша атауы',
      ruName: 'Орысша атауы',
      enName: 'Ағылшын тілі атауы',
      kkDescription: 'Сипаттама қазақ тілінде',
      ruDescription: 'Сипаттама орыс тілінде',
      enDescription: 'Сипаттама ағылшын тілінде',
      create: 'Жасау',
      metaTitle: 'Метадеректерді теріңіз',
      addMeta: 'Метадеректер бөлімін қосыңыз',
      code: 'Өріс коды',
      type: 'Өріс түрі(типі)',
      required: 'Міндетті өріс',
      fillTypes: 'Барлық метадеректердің түрін (типін) таңдаңыз',
      successMessage: 'Жаңа түр (тип) қосылды',
      errorMessage: 'Жаңа түр (тип)қосылмады ',
      recurrent: 'Өрістердің массиві(жиымы)'
    },

    workWithDocs: {
      title: 'Құжаттармен жұмыс істеу',
      button: 'Жаңа құжат',
      searchByName: 'Файл атауы бойынша іздеу',
      catalog: 'Каталог',
      docType: 'Құжат түрі',
      noMessage: 'Ешнәрсе табылмады',
      colFilename: 'Файл атауы',
      colDateVer: 'Нүсқаның күні',
      colCatalog: 'Каталогы',
      colDocType: 'Құжат түрі (типі)',
      edit: 'өңдеу',
      delete: 'жою',
      successDeleted: 'Жойылған',
      naPlaceholder: '',
    },

    newDoc: {
      title: 'Құжаттармен жұмыс істеу / Жаңа құжат',
      docType: 'Құжат түрін іздеп, таңдаңыз',
      edc: 'ЭСҚ',
      upload: 'Құжатты жүктеп алыңыз',
      metaTitle: 'Құжаттың метадеректері',
      submit: 'Жасау',
      chooseTypeError: 'Құжат түрін таңдаңыз',
      chooseCatalogError: 'Каталогты таңдаңыз',
      successMessage: 'Құжат қосылды',
      errorMessage: 'Құжатты қосу мүмкін болмады',
      catalog: 'Каталогты іздеу және таңдау'
    },

    viewDoc: {
      title: 'Құжаттармен жұмыс / Құжатты қарау',
      catalog: 'Каталогы',
      docType: 'Құжат түрі (типі)',
      docVersion: 'Құжат нұсқасы',
      operator: 'Оператор',
      edc: 'ЭСҚ',
      uploadedFile: 'Жүктелген файл',
      editButton: 'Өңдеу үшін',
      deleteButton: 'Жою',
      versionHistory: 'Нұсқалар тарихы',
      colFilename: 'Файл атауы',
      colDateVer: 'Нүсқаның күні',
      colNumVer: 'Нұсқа нөмірі',
      colOperator: 'Оператор',
      viewFailed: 'Құжатты көру мүмкін болмады.',
      viewStart: 'Файлды көру үшін дайындау',
      downloadFailed: 'Құжатты жүктеу мүмкін болмады',
      downloadStart: 'Файлды жүктеу үшін дайындау'
    },

    search: {
      title: 'Іздеу',
      catalog: 'Каталогы',
      docType: 'Құжат түрі (типі)',
      filename: 'Файл атауы',
      dateFrom: 'Басталу күні',
      dateTo: 'Аяқталу күні',
      find: 'Табу үшін',
      metaTitle: 'Құжаттың метадеректері',
      resultsTitle: 'Іздеу нәтижесі',
      exactCheckbox: 'Толық сәйкестік',
      findFailed: 'Іздеу деректерін жіберу мүмкін аяқталды',
      noMessage: 'Ешнәрсе табылмады',
      requiredData: 'Каталог және құжат түрін (типін) көрсету керек',
      colFilename: 'Файл атауы',
      colCatalog: 'Каталогы',
      colDocType: 'Құжат түрі (типі)',
      edit: 'өңдеу',
      delete: 'жою',
      constraint: "Шектеу",
      op: {
        GREATER_OR_EQUALS: "Үлкен немесе тең",
        LESS_OR_EQUALS: "Аз немесе тең",
        GREATER: "Көбірек",
        LESS: "Аз",
        EXACT: "Дәлірек",
        BETWEEN: "Арасындағы",
        CONTAINS: "Қамтиды",
      }
    },

    editDocType: {
      title: 'Құжат түрін өңдеу',
      errorMessage: 'Құжат түрін жүктеу мүмкін болмады',
      saveChanges: 'Өзгерістерді сақтаңыз',
      successMessage: 'Өзгерістер сақталды',
      cantSave: 'Өзгерістерді сақтау мүмкін болмады'
    },

    viewDocType: {
      title: 'Құжаттың түрін қарау'
    },

    editDoc: {
      subscribe: 'Қол қою',
      saveChanges: 'Өзгерістерді сақтаңыз',
      title: 'Құжатты өңдеңіз',
      ncalayerError: 'NCALayer іске қосылмаған',
      subscribeCanceled: 'Қол қою тоқтатылды',
      cantGetXml: 'XML-ді алу мүмкін болмады'
    },

    errors: {
      INCORRECT_USERNAME_PASSWORD: "Пайдаланушы аты немесе құпия сөз қате.",
      TOKEN_HAS_EXPIRED: "Қолжетімділік таңбасының (токенінің) мерзімі аяқталған.",
      REQUIRED_FIELD_IS_EMPTY: "Нысанның барлық қажетті өрістерін толтыруыңыз қажет.",
      DIRECTORY_NOT_FOUND: "Директория табылмады / жоқ.",
      DIRECTORY_CANNOT_DELETE_OBJECTS_EXIST: "Директорияны жою мүмкін емес, оған құжаттар бекітілген.",
      DIRECTORY_DUPLICATE: "Жүйеде осындай директория бар.",
      CANNOT_CREATE_DIRECTORY: "Директорияны құру мүмкін емес (директорияны құруға құқықтары жоқ).",
      DOCUMENT_TYPE_NOT_FOUND: "Құжат түрі табылмады / жоқ.",
      DOCUMENT_TYPE_MISMATCHES_DATA_TYPE: "Құжат, құжат түріне сәйкес келмейді.",
      DOCUMENT_DATA_MISMATCHES_DATA_TYPE: "Құжат деректері деректер түріне сәйкес келмейді.",
      DOCUMENT_TYPE_CANNOT_DELETE_OBJECTS_EXIST: "Құжаттың түрін жою мүмкін емес, оған құжаттар бекітілген.",
      DOCUMENT_TYPE_INVALID_OPERATION_FIELD_DELETE: "Өрісті жою мүмкін емес, осы түрдегі өрістермен толтырылған құжаттар бар.",
      DOCUMENT_TYPE_INVALID_OPERATION_FIELD_REQUIRED_CHANGE: "Өрістің міндеттілігін өзгерту мүмкін емес, осы түрдегі толтырылмаған өрісті құжаттар бар.",
      DOCUMENT_TYPE_INVALID_OPERATION_FIELD_DATA_TYPE_CHANGE: "Өрістің деректер түрін өзгерту мүмкін емес, осы түрдегі толтырылған өрісті  құжаттар бар.",
      DOCUMENT_TYPE_META_DATA_DUPLICATE: "Деректер түрінің мета өрістері қайталанады.",
      DOCUMENT_NOT_FOUND: "Құжат табылмады / жоқ.",
      WRONG_DATE_FORMAT: "Күн пішімі қате енгізілген.",
      DOCUMENT_DATA_MISMATCH_DATA_TYPE: "Құжат өрістерінің бірінде деректер түрі (типі) жарамсыз.",
      DOCUMENT_WRONG_SIGNATURE: "Құжаттың қолтаңбасы жарамсыз.",
      DOCUMENT_VERSION_DOES_NOT_EXIST: "Бұл нұсқадағы құжат жоқ.",
      DOCUMENT_TYPE_DUPLICATE: "Жүйеде мұндай құжат түрі бар.",
      FILE_MUST_BE_PRESENT: "Файлды жүктеу қажет.",
      FILE_READ_EXCEPTION: "Файлды оқу / табу мүмкін емес.",
      FILE_SAVE_EXCEPTION: "Файлды сақтау қатесі.",
      FILE_DOES_NOT_EXIST: "Файл жоқ.",
      NULL_SIGNATURE_NODE: "Қолтаңба жоқ.",
      CANT_FIND_CERTIFICATE: "Тиісті сертификат табылмады.",
      KEY_NOT_FOUND: "Кілт табылмады.",
      ILLEGAL_CERTIFICATE: "Қате сертификат.",
      ILLEGAL_IIN_SIZE: "ЖСН өлшемі дұрыс енгізілмеген.",
      COULD_NOT_FIND_CERTIFICATE_IIN: "ЖСН сертификатын табу мүмкін емес.",
      VERIFICATION_FAILED: "Қате мәтінін анықтау мүмкін емес.",
      UNKNOWN_ERROR: "Белгісіз қате.",
      UNHANDLED_EXCEPTION: "Бірдеңе дұрыс емес.",
      ACTION_NOT_ALLOWED: 'Әрекетке рұқсат берілмейді.',
      AUTH_SERVICE_IS_UNAVAILABLE: 'Авторизациялау сервисі қолжетімсіз.'
    },

    routeError: {
      noPage: 'Мұндай бет жоқ'
    },

    docTypes: {
      date: "Күні",
      number: "Саны",
      text: "Мәтін",
      boolean: "Бульдік мән",
    }
  }
};
