import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {LinearProgress, TextField} from '@material-ui/core';
import {translate} from "../../localization/i18n";
import {fetchCatalog} from "../../store/actions/catalogManagement";

class ViewCatalog extends Component {
  componentDidMount() {
    this.props.fetchCatalog(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }
  }

  render() {
    const {kkName, ruName, enName, kkDescription, ruDescription, enDescription} = this.props.catalog

    return (
      <Fragment>
        <h3>{translate('viewCatalog.title')}</h3>
        {this.props.isLoading ? <LinearProgress className="preLoader"/> :
          <form>
            <div className="new-catalog_form-container">
              <TextField
                label={translate('newCatalog.kkName')}
                margin="dense"
                variant="outlined"
                name="kkName"
                value={kkName}
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label={translate('newCatalog.ruName')}
                margin="dense"
                variant="outlined"
                name="ruName"
                value={ruName}
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label={translate('newCatalog.enName')}
                margin="dense"
                variant="outlined"
                name="enName"
                value={enName}
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label={translate('newCatalog.kkDescription')}
                multiline
                rows="3"
                margin="dense"
                variant="outlined"
                name="kkDescription"
                value={kkDescription}
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label={translate('newCatalog.ruDescription')}
                multiline
                rows="3"
                margin="dense"
                variant="outlined"
                name="ruDescription"
                value={ruDescription}
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label={translate('newCatalog.enDescription')}
                multiline
                rows="3"
                margin="dense"
                variant="outlined"
                name="enDescription"
                value={enDescription}
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </div>
          </form>}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  isLoading: state.catalogManagement.isLoading,
  catalog: state.catalogManagement.catalog
});

const mapDispatchToProps = dispatch => ({
  fetchCatalog: id => dispatch(fetchCatalog(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCatalog);
