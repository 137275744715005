import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {translate, translateCombinedError} from "../../localization/i18n";
import {
  Button,
  TextField,
  LinearProgress
} from '@material-ui/core';
import axios from '../../axios-api';
import {NotificationManager} from "react-notifications";
import cloneDeep from 'lodash/cloneDeep';
import {changeCatalog} from "../../store/actions/catalogManagement";
import {isContainsCyrillic} from "../../components/Utils";

class EditCatalog extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    axios.get(`/directory/${this.props.match.params.id}`)
      .then(response => {
          if (response.data.status === 1) {
            const state = response.data.object;
            state.loading = false;
            delete state.id;
            this.setState(state);
          } else {
            this.setState({loading: false});
            NotificationManager.error(translateCombinedError('editCatalog.errorMessage', response.data.message));
          }
        },
        error => {
          if (error.response) {
            NotificationManager.error(translateCombinedError('editCatalog.errorMessage', error.response.data.message));
          } else {
            NotificationManager.error(translate('editCatalog.errorMessage'));
          }
          this.setState({loading: false});
        });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }
  }

  submitFormHandler = event => {
    event.preventDefault();
    let data = cloneDeep(this.state);
    delete data.loading;
    this.props.changeCatalog(this.props.match.params.id, data);
  };

  inputChangeHandler = event => {
    switch (event.target.name) {
      case 'enName': case 'enDescription':
      if (!isContainsCyrillic(event.target.value)) {
        this.setState({[event.target.name]: event.target.value});
      }
      break;
      default:
        this.setState({[event.target.name]: event.target.value});
    }
  };

  render() {
    return (
      <Fragment>
        <h3>{translate('editCatalog.title')}</h3>
        {this.state.loading ? <LinearProgress className="preLoader"/> :
          <form onSubmit={this.submitFormHandler} autoComplete="off">
            <div className="new-catalog_form-container">
              <TextField
                label={translate('newCatalog.kkName')}
                margin="dense"
                variant="outlined"
                name="kkName"
                value={this.state.kkName}
                onChange={this.inputChangeHandler}
                fullWidth
                required
              />
              <TextField
                label={translate('newCatalog.ruName')}
                margin="dense"
                variant="outlined"
                name="ruName"
                value={this.state.ruName}
                onChange={this.inputChangeHandler}
                fullWidth
                required
              />
              <TextField
                label={translate('newCatalog.enName')}
                margin="dense"
                variant="outlined"
                name="enName"
                value={this.state.enName}
                onChange={this.inputChangeHandler}
                fullWidth
              />
              <TextField
                label={translate('newCatalog.kkDescription')}
                multiline
                rows="3"
                margin="dense"
                variant="outlined"
                name="kkDescription"
                value={this.state.kkDescription}
                onChange={this.inputChangeHandler}
                fullWidth
                required
              />
              <TextField
                label={translate('newCatalog.ruDescription')}
                multiline
                rows="3"
                margin="dense"
                variant="outlined"
                name="ruDescription"
                value={this.state.ruDescription}
                onChange={this.inputChangeHandler}
                fullWidth
                required
              />
              <TextField
                label={translate('newCatalog.enDescription')}
                multiline
                rows="3"
                margin="dense"
                variant="outlined"
                name="enDescription"
                value={this.state.enDescription}
                onChange={this.inputChangeHandler}
                fullWidth
              />
              <Button
                variant="contained" color="primary" type="submit"
                className="new-catalog_submit-btn">{translate('editCatalog.saveChanges')}
              </Button>
            </div>
          </form>}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language
});

const mapDispatchToProps = dispatch => ({
  changeCatalog: (id, data) => dispatch(changeCatalog(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCatalog);
