import {
  FETCH_CATALOGS, FETCH_CATALOGS_SUCCESS, FETCH_CATALOGS_FAILURE,
  ADD_CATALOG, ADD_CATALOG_SUCCESS, ADD_CATALOG_FAILURE,
  FETCH_CATALOG, FETCH_CATALOG_SUCCESS, FETCH_CATALOG_FAILURE, RESET_CATALOG
} from "../actionTypes";

const initialState = {
  pagesCount: 0,
  pageSize: 0,
  catalogs: [],
  isLoading: false,
  error: null,
  catalog: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATALOGS:
      return {...state, isLoading: true, error: null, pagesCount: 0, pageSize: 0, catalogs: []};
    case FETCH_CATALOGS_SUCCESS:
      return {...state, isLoading: false, pagesCount: action.pagesCount, pageSize: action.pageSize, catalogs: action.catalogs};
    case FETCH_CATALOGS_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case ADD_CATALOG:
      return {...state, isLoading: true, error: null};
    case ADD_CATALOG_SUCCESS:
      return {...state, isLoading: false};
    case ADD_CATALOG_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case FETCH_CATALOG:
      return {...state, isLoading: true, error: null};
    case FETCH_CATALOG_SUCCESS:
      return {...state, isLoading: false, catalog: action.catalog};
    case FETCH_CATALOG_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case RESET_CATALOG:
      return {...state, catalog: {}};

    default:
      return state;
  }
};

export default reducer;
