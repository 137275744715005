import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Button, TextField} from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import {translate} from "../../localization/i18n";
import {addCatalog} from "../../store/actions/catalogManagement";
import './NewCatalog.css';
import {isContainsCyrillic} from "../../components/Utils";

class NewCatalog extends Component {
  state = {
    kkName: '',
    ruName: '',
    enName: '',
    kkDescription: '',
    ruDescription: '',
    enDescription: '',
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }
  }

  submitFormHandler = event => {
    event.preventDefault();

    this.props.addCatalog(cloneDeep(this.state))
  };

  inputChangeHandler = event => {
    switch (event.target.name) {
      case 'enName': case 'enDescription':
        if (!isContainsCyrillic(event.target.value)) {
          this.setState({[event.target.name]: event.target.value});
        }
        break;
      default:
        this.setState({[event.target.name]: event.target.value});
    }
  };

  render() {
    return (
      <Fragment>
        <h3>{translate('newCatalog.title')}</h3>
        <form onSubmit={this.submitFormHandler} autoComplete="off">
          <div className="new-catalog_form-container">
            <TextField
              label={translate('newCatalog.kkName')}
              margin="dense"
              variant="outlined"
              name="kkName"
              value={this.state.kkName}
              onChange={this.inputChangeHandler}
              fullWidth
              required
            />
            <TextField
              label={translate('newCatalog.ruName')}
              margin="dense"
              variant="outlined"
              name="ruName"
              value={this.state.ruName}
              onChange={this.inputChangeHandler}
              fullWidth
              required
            />
            <TextField
              label={translate('newCatalog.enName')}
              margin="dense"
              variant="outlined"
              name="enName"
              value={this.state.enName}
              onChange={this.inputChangeHandler}
              fullWidth
            />
            <TextField
              label={translate('newCatalog.kkDescription')}
              multiline
              rows="3"
              margin="dense"
              variant="outlined"
              name="kkDescription"
              value={this.state.kkDescription}
              onChange={this.inputChangeHandler}
              fullWidth
              required
            />
            <TextField
              label={translate('newCatalog.ruDescription')}
              multiline
              rows="3"
              margin="dense"
              variant="outlined"
              name="ruDescription"
              value={this.state.ruDescription}
              onChange={this.inputChangeHandler}
              fullWidth
              required
            />
            <TextField
              label={translate('newCatalog.enDescription')}
              multiline
              rows="3"
              margin="dense"
              variant="outlined"
              name="enDescription"
              value={this.state.enDescription}
              onChange={this.inputChangeHandler}
              fullWidth
            />
            <Button
              variant="contained" color="primary" type="submit"
              className="new-catalog_submit-btn">{translate('newCatalog.create')}
            </Button>
          </div>
        </form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language
});

const mapDispatchToProps = dispatch => ({
  addCatalog: data => dispatch(addCatalog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCatalog)
