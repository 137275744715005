import {
  ADD_DOCUMENT_TYPE, ADD_DOCUMENT_TYPE_FAILURE, ADD_DOCUMENT_TYPE_SUCCESS,
  FETCH_DOCUMENT_TYPE, FETCH_DOCUMENT_TYPE_SUCCESS, FETCH_DOCUMENT_TYPE_FAILURE,
  FETCH_DOCUMENT_TYPES, FETCH_DOCUMENT_TYPES_SUCCESS, FETCH_DOCUMENTS_TYPE_FAILURE, RESET_DOC_TYPE,
} from "../actionTypes";

const initialState = {
  pagesCount: 0,
  pageSize: 0,
  docTypes: [],
  isLoading: false,
  error: null,
  docType: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_TYPES:
      return {...state, isLoading: true, error: null, pagesCount: 0, pageSize: 0, docTypes: []};
    case FETCH_DOCUMENT_TYPES_SUCCESS:
      return {...state, isLoading: false, pagesCount: action.pagesCount, pageSize: action.pageSize, docTypes: action.types};
    case FETCH_DOCUMENTS_TYPE_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case ADD_DOCUMENT_TYPE:
      return {...state, isLoading: true, error: null};
    case ADD_DOCUMENT_TYPE_SUCCESS:
      return {...state, isLoading: false};
    case ADD_DOCUMENT_TYPE_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case FETCH_DOCUMENT_TYPE:
      return {...state, isLoading: true, error: null};
    case FETCH_DOCUMENT_TYPE_SUCCESS:
      return {...state, isLoading: false, docType: action.docType};
    case FETCH_DOCUMENT_TYPE_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case RESET_DOC_TYPE:
      return {...state, docType: {}};

    default:
      return state;
  }
};

export default reducer;
