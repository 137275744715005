import axios from '../../axios-api';
import {NotificationManager} from 'react-notifications';
import {translate, translateCombinedError} from "../../localization/i18n";

import {
  ADD_DOCUMENT_TYPE, ADD_DOCUMENT_TYPE_FAILURE, ADD_DOCUMENT_TYPE_SUCCESS,
  CHANGE_DOCUMENT_TYPE, CHANGE_DOCUMENT_TYPE_FAILURE, CHANGE_DOCUMENT_TYPE_SUCCESS,
  DELETE_DOCUMENT_TYPE, DELETE_DOCUMENT_TYPE_FAILURE, DELETE_DOCUMENT_TYPE_SUCCESS,
  FETCH_DOCUMENT_TYPE, FETCH_DOCUMENT_TYPE_FAILURE, FETCH_DOCUMENT_TYPE_SUCCESS,
  FETCH_DOCUMENT_TYPES, FETCH_DOCUMENT_TYPES_SUCCESS, FETCH_DOCUMENTS_TYPE_FAILURE,
} from "../actionTypes";

function fetchDocumentTypes() {
  return {type: FETCH_DOCUMENT_TYPES};
}

function fetchDocumentTypesSuccess(pagesCount, pageSize, types) {
  return {type: FETCH_DOCUMENT_TYPES_SUCCESS, pagesCount, pageSize, types};
}

function fetchDocumentTypesFailure(error) {
  return {type: FETCH_DOCUMENTS_TYPE_FAILURE, error};
}

export function fetchDocTypes(page) {
  return dispatch => {
    dispatch(fetchDocumentTypes());

    axios.get(`/documentType?page=${page}`)
      .then(response => {
          if (response.data.status === 1) {
            dispatch(fetchDocumentTypesSuccess(response.data.object.pagesCount, response.data.object.pageSize, response.data.object.data));
          } else {
            dispatch(fetchDocumentTypesFailure(response.data));
          }
        },
        error => {
          if (error.response) {
            NotificationManager.error(translateCombinedError('documentTypeManagement.fetchFailed', error.response.data.message));
          } else {
            NotificationManager.error(translate('documentTypeManagement.fetchFailed'));
          }
          dispatch(fetchDocumentTypesFailure(error));
        });
  };
}

function addDocumentType() {
  return {type: ADD_DOCUMENT_TYPE};
}

function addDocumentTypeSuccess() {
  return {type: ADD_DOCUMENT_TYPE_SUCCESS};
}

function addDocumentTypeFailure(error) {
  return {type: ADD_DOCUMENT_TYPE_FAILURE, error};
}

export function addDocType(data) {
  return dispatch => {
    dispatch(addDocumentType());
    axios.post('/documentType', data).then(response => {
      if (response.data.status === 1) {
        NotificationManager.success(translate('newDocType.successMessage'));
        dispatch(addDocumentTypeSuccess());
      } else {
        NotificationManager.error(translateCombinedError('newDocType.errorMessage', response.data.message));
        dispatch(addDocumentTypeFailure(response.data));
      }
    }, error => {
      if (error.response) {
        NotificationManager.error(translateCombinedError('newDocType.errorMessage', error.response.data.message));
      } else {
        NotificationManager.error(translate('newDocType.errorMessage'));
      }
      dispatch(addDocumentTypeFailure(error));
    });
  };
}

function deleteDocumentType() {
  return {type: DELETE_DOCUMENT_TYPE};
}

function deleteDocumentTypeSuccess() {
  return {type: DELETE_DOCUMENT_TYPE_SUCCESS};
}

function deleteDocumentTypeFailure(error) {
  return {type: DELETE_DOCUMENT_TYPE_FAILURE, error};
}

export function deleteDocType(id, page, refetchOnDelete = fetchDocTypes) {
  return dispatch => {
    dispatch(deleteDocumentType());
    axios.delete(`/documentType/${id}`).then(response => {
      if (response.data.status === 1) {
        NotificationManager.success(translate('documentTypeManagement.deletedSuccess'));
        dispatch(refetchOnDelete(page));
        dispatch(deleteDocumentTypeSuccess());
      } else {
        NotificationManager.error(translateCombinedError('documentTypeManagement.deletedFailure', response.data.message));
        dispatch(deleteDocumentTypeFailure(response.data));
      }
    }, error => {
      if (error.response) {
        NotificationManager.error(translateCombinedError('documentTypeManagement.deletedFailure', error.response.data.message));
      } else {
        NotificationManager.error(translate('documentTypeManagement.deletedFailure'));
      }
      dispatch(deleteDocumentTypeFailure(error));
    });
  };
}

function changeDocType() {
  return {type: CHANGE_DOCUMENT_TYPE};
}

function changeDocTypeSuccess() {
  return {type: CHANGE_DOCUMENT_TYPE_SUCCESS};
}

function changeDocTypeFailure(error) {
  return {type: CHANGE_DOCUMENT_TYPE_FAILURE, error};
}

export function changeDocumentType(id, data) {
  return dispatch => {
    dispatch(changeDocType());
    axios.put(`/documentType/${id}`, data).then(response => {
      if (response.data.status === 1) {
        NotificationManager.success(translate('editDocType.successMessage'));
        dispatch(changeDocTypeSuccess());
      } else {
        NotificationManager.error(translateCombinedError('editDocType.cantSave', response.data.message));
        dispatch(changeDocTypeFailure(response.data));
      }
    }, error => {
      if (error.response) {
        NotificationManager.error(translateCombinedError('editDocType.cantSave', error.response.data.message));
      } else {
        NotificationManager.error(translate('editDocType.cantSave'));
      }
      dispatch(changeDocTypeFailure(error));
    });
  };
}

function fetchDocumentType() {
  return {type: FETCH_DOCUMENT_TYPE};
}

function fetchDocumentTypeSuccess(docType) {
  return {type: FETCH_DOCUMENT_TYPE_SUCCESS, docType};
}

function fetchDocumentTypeFailure(error) {
  return {type: FETCH_DOCUMENT_TYPE_FAILURE, error};
}

export function fetchDocType(id) {
  return dispatch => {
    dispatch(fetchDocumentType());
    axios.get(`/documentType/${id}`).then(response => {
      if (response.data.status === 1) {
        dispatch(fetchDocumentTypeSuccess(response.data.object));
      } else {
        dispatch(fetchDocumentTypeFailure(response.data));
      }
    }, error => {
      dispatch(fetchDocumentTypeFailure(error));
    });
  };
}
