import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import CatalogManagement from './containers/CatalogManagement/CatalogManagement';
import NewCatalog from './containers/NewCatalog/NewCatalog';
import DocTypeManagement from "./containers/DocTypeManagement/DocTypeManagement";
import NewDocumentsType from "./containers/NewDocumentsType/NewDocumentsType";
import WorkWithDocuments from "./containers/WorkWithDocuments/WorkWithDocuments";
import NewDocument from "./containers/NewDocument/NewDocument";
import ViewDocument from "./containers/ViewDocument/ViewDocument";
import Search from './containers/Search/Search';
import EditCatalog from "./containers/EditCatalog/EditCatalog";
import EditDocType from "./containers/EditDocType/EditDocType";
import ViewCatalog from "./containers/ViewCatalog/ViewCatalog";
import ViewDocumentType from "./containers/ViewDocumentType/ViewDocumentType";
import Login from "./containers/Login/Login";
import EditDocument from "./containers/EditDocument/EditDocument";
import {translate} from "./localization/i18n";

const ProtectedRoute = ({isAllowed, ...props}) => (
  isAllowed ? <Route {...props} /> : <Redirect to="/login"/>
);

const Routes = ({user}) => (
  <Switch>
    <Route path="/" exact component={() => <Redirect to="/catalogs"/>}/>
    <ProtectedRoute path="/catalogs" exact component={CatalogManagement} isAllowed={user}/>
    <ProtectedRoute path="/catalogs/edit/:id" exact component={EditCatalog} isAllowed={user}/>
    <ProtectedRoute path="/catalogs/new_catalog" exact component={NewCatalog} isAllowed={user}/>
    <ProtectedRoute path="/view_catalog/:id" exact component={ViewCatalog} isAllowed={user}/>
    <ProtectedRoute path="/documents_type" exact component={DocTypeManagement} isAllowed={user}/>
    <ProtectedRoute path="/documents_type/edit/:id" exact component={EditDocType} isAllowed={user}/>
    <ProtectedRoute path="/documents_type/new_document_type" exact component={NewDocumentsType} isAllowed={user}/>
    <ProtectedRoute path="/documents_type/view_document_type/:id" exact component={ViewDocumentType} isAllowed={user}/>
    <ProtectedRoute path="/documents_list" exact component={WorkWithDocuments} isAllowed={user}/>
    <ProtectedRoute path="/documents_list/new_document" exact component={NewDocument} isAllowed={user}/>
    <ProtectedRoute path="/documents_list/:dirID/type/:typeID/view_document/:id" exact component={ViewDocument}
                    isAllowed={user}/>
    <ProtectedRoute path="/documents_list/:dirID/type/:typeID/edit_document/:id" exact component={EditDocument}
                    isAllowed={user}/>
    <ProtectedRoute path="/search" exact component={Search} isAllowed={user}/>
    <Route path="/login" exact component={Login}/>
    <Route render={() => <h2>{translate('routeError.noPage')}</h2>}/>
  </Switch>
);

export default Routes;
