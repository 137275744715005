import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {translate} from "../../localization/i18n";
import {Button, TextField} from '@material-ui/core';
import {LinearProgress} from '@material-ui/core';
import AutoComplete from "../../components/AutoComplete/AutoComplete";
import SearchBox from "../../components/SearchBox/SearchBox";
import {fetchCatalogs} from "../../store/actions/catalogManagement";
import {fetchDocTypes} from "../../store/actions/docTypeManagement";
import {searchCatalogs, searchDocTypes} from "../../store/actions/search";
import {deleteDocument, searchDocs, resetSearchDocs} from "../../store/actions/workWithDocs";
import DataTable from "../../components/DataTable/DataTable";
import ConfirmationWindow from "../../components/ConfirmationWindow/ConfirmationWindow";
import {calendarToDate} from "../../components/Utils";
import './WorkWithDocuments.css';

class WorkWithDocuments extends Component {
  state = {
    directories: [],
    documentTypes: [],
    docName: '',
    dateFrom: '',
    dateTo: '',
    isOpen: false,
    formData: {},
    curPage: 1,
  };

  componentDidMount() {
    this.props.fetchDocTypes(1);
    this.props.fetchCatalogs(1);
  }

  componentWillUnmount() {
    this.props.resetSearchDocs();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }

    if (prevState.curPage !== this.state.curPage || prevState.formData !== this.state.formData) {
      this.props.searchDocs(this.state.curPage, this.state.formData);
    }
  }

  newDocument = () => {
    this.props.history.push('/documents_list/new_document');
  };

  editDocument = (dirID, typeID, id) => {
    this.props.history.push(`/documents_list/${dirID}/type/${typeID}/edit_document/${id}`);
  };

  fillRequestData() {
    const data = {
      directories: this.state.directories.map(dir => dir.value),
      documentTypes: this.state.documentTypes.map(type => type.value),
      fileName: this.state.docName,
    };

    if (this.state.dateFrom) {
      data.dateFrom = calendarToDate(this.state.dateFrom);
    }

    if (this.state.dateTo) {
      data.dateTo = calendarToDate(this.state.dateTo);
    }

    return data;
  }

  onChangeFilename = value => {
    this.props.resetSearchDocs();

    const formData = this.fillRequestData();
    formData.fileName = value;

    this.setState({docName: value, formData});
  };

  onChangeDate = (key, value) => {
    this.props.resetSearchDocs();

    const formData = this.fillRequestData();

    if (value) {
      formData[key] = calendarToDate(value);
    } else {
      delete formData[key];
    }

    if (formData.directories.length > 0 || formData.documentTypes.length > 0) {
      this.setState({formData});
    }

    this.setState({[key]: value});
  };

  onSelect = (key, value) => {
    this.props.resetSearchDocs();

    const formData = this.fillRequestData();

    formData[key] = value.map(val => val.value);

    if (formData.directories.length > 0 || formData.documentTypes.length > 0) {
      this.setState({formData});
    }

    this.setState({[key]: value});
  };

  onType = (value, event) => {
    if (!value || value.length > 2) {
      switch (event) {
        case 'catalog':
          this.props.searchCatalogs(value);
          break;
        case 'docTypes':
          this.props.searchDocTypes(value);
          break;
        default:
          break;
      }
    }
  };

  viewDocument = (dirID, typeID, id) => {
    this.props.history.push(`/documents_list/${dirID}/type/${typeID}/view_document/${id}`);
  };

  confirm = (dirID, docID) => {
    this.setState({isOpen: true, dirID, docID});
  };

  deleteDoc = () => {
    this.props.deleteDoc(this.state.dirID, this.state.docID, this.state.curPage, this.state.formData);
    this.setState({isOpen: false});
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  selectTranslation(kz, ru) {
    switch (this.props.lang) {
      case 'KZ_kz':
        return kz;
      default:
        return ru;
    }
  }

  makeSuggestions(options) {
    return options.map(obj => {
      const selectLang = () => this.selectTranslation(obj.kkName, obj.ruName);

      return {
        value: obj.id,
        label: selectLang(),
        dynLabel: selectLang,
      };
    });
  }

  static orList(list1, list2) {
    if (list1.length > 0) return list1;
    if (list2.length > 0) return list2;
    return [];
  }

  onPageSwitch = curPage => {
    this.setState({curPage});
  };

  render() {
    const {foundCatalogs, foundDocTypes, catalogs, docTypes, searchingDocs, foundDocs} = this.props;
    const {isCatalogsLoading, isDocTypesLoading} = this.props;

    const {directories, documentTypes} = this.state;

    const catalogSuggestions = this.makeSuggestions(WorkWithDocuments.orList(foundCatalogs, catalogs));
    const docTypeSuggestions = this.makeSuggestions(WorkWithDocuments.orList(foundDocTypes, docTypes));

    const catalogNameById = id => {
      const name = directories.filter(({value}) => value === id)[0];
      return name ? name.dynLabel : () => translate('workWithDocs.naPlaceholder');
    };

    const docTypeNameById = id => {
      const name = documentTypes.filter(({value}) => value === id)[0];
      return name ? name.dynLabel : () => translate('workWithDocs.naPlaceholder');
    };

    const docs = foundDocs.map(doc => ({
      id: doc.id,
      cols: [
        {value: doc.fileName, cb: () => this.viewDocument(doc.directoryId, doc.documentType, doc.id)},
        {value: doc.version},
        {value: catalogNameById(doc.directoryId)},
        {value: docTypeNameById(doc.documentType)},
        {
          value: () => translate('workWithDocs.edit'),
          cb: () => this.editDocument(doc.directoryId, doc.documentType, doc.id)
        },
        {
          value: () => translate('workWithDocs.delete'),
          cb: () => this.confirm(doc.directoryId, doc.id)
        },
      ]
    }));
    return (
      <Fragment>
        <h3>{translate('workWithDocs.title')}</h3>
        {(isCatalogsLoading || isDocTypesLoading)
          ?
          <LinearProgress className="preLoader"/>
          :
          <div>
            <form className="work-with-docs_search-form">
              <AutoComplete label={translate('workWithDocs.catalog')}
                            placeholder={translate('workWithDocs.catalog')}
                            suggestions={catalogSuggestions}
                            onChange={value => this.onSelect('directories', value)}
                            onType={value => this.onType(value, 'catalog')}/>
              <AutoComplete label={translate('workWithDocs.docType')}
                            placeholder={translate('workWithDocs.docType')}
                            suggestions={docTypeSuggestions}
                            onChange={value => this.onSelect('documentTypes', value)}
                            onType={value => this.onType(value, 'docTypes')}/>
              <SearchBox className="work-with-docs_search"
                         array={[]}
                         selectChangeHandler={event => this.onChangeFilename(event.target.value)}
                         placeholder={translate('workWithDocs.searchByName')}
                         filledHandler={() => {
                         }}
                         icon="none"/>
              <div className="work-with-docs">
                <TextField
                  variant="outlined"
                  margin="dense"
                  label={translate('search.dateFrom')}
                  type="date"
                  value={this.state.dateFrom}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => this.onChangeDate('dateFrom', event.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label={translate('search.dateTo')}
                  type="date"
                  value={this.state.dateTo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => this.onChangeDate('dateTo', event.target.value)}
                />
              </div>
            </form>
            <Button variant="contained" color="primary" style={{display: 'block', clear: 'both'}}
                    onClick={this.newDocument}>{translate('workWithDocs.button')}</Button>
          </div>
        }
        <DataTable
          onPageSwitch={this.onPageSwitch}
          curPage={this.state.curPage}
          numPages={this.props.pagesCount}
          rowsPerPage={this.props.pageSize}
          isLoading={searchingDocs}
          header={[
            {id: "filename", label: translate('workWithDocs.colFilename')},
            {id: "date_version", label: translate('workWithDocs.colDateVer')},
            {id: "catalog", label: translate('workWithDocs.colCatalog')},
            {id: "doc_type", label: translate('workWithDocs.colDocType')},
            {id: null},
            {id: null}
          ]}
          rows={docs}
          orderBy='filename'/>
        <ConfirmationWindow isOpen={this.state.isOpen}
                            handleClose={this.handleClose}
                            delete={this.deleteDoc}/>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  foundCatalogs: state.search.foundCatalogs,
  foundDocTypes: state.search.foundDocTypes,
  catalogs: state.catalogManagement.catalogs,
  isCatalogsLoading: state.catalogManagement.isLoading,
  docTypes: state.docTypeManagement.docTypes,
  isDocTypesLoading: state.docTypeManagement.isLoading,
  searchingDocs: state.workWithDocs.searchingDocs,
  pagesCount: state.workWithDocs.pagesCount,
  pageSize: state.workWithDocs.pageSize,
  foundDocs: state.workWithDocs.foundDocs
});

const mapDispatchToProps = dispatch => ({
  fetchCatalogs: page => dispatch(fetchCatalogs(page)),
  fetchDocTypes: page => dispatch(fetchDocTypes(page)),
  searchCatalogs: word => dispatch(searchCatalogs(word)),
  searchDocTypes: word => dispatch(searchDocTypes(word)),
  searchDocs: (page, data) => dispatch(searchDocs(page, data)),
  resetSearchDocs: () => dispatch(resetSearchDocs()),
  deleteDoc: (dirID, docID, page, data) => dispatch(deleteDocument(dirID, docID, page, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkWithDocuments);
