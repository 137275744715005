import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Button} from "@material-ui/core";
import SimpleSearchBox from "../../components/SimpleSearchBox/SimpleSearchBox";
import DataTable from "../../components/DataTable/DataTable";
import {translate} from "../../localization/i18n";
import {deleteDocType, fetchDocTypes} from "../../store/actions/docTypeManagement";
import ConfirmationWindow from "../../components/ConfirmationWindow/ConfirmationWindow";
import {searchDocType} from "../../store/actions/search";
import './DocTypeManagement.css';

class DocTypeManagement extends Component {
  state = {
    isOpen: false,
    id: null,
    curPage: 1,
    searchWord: null,
    searchCurPage: 1,
  };

  componentDidMount() {
    this.props.fetchDocTypes(this.state.curPage);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }

    const {curPage, searchCurPage, searchWord} = this.state;

    if (searchWord) {
      if (prevState.searchCurPage !== searchCurPage) {
        this.props.searchWord(searchWord, searchCurPage);
      }
    } else {
      if (prevState.curPage !== curPage) {
        this.props.fetchDocTypes(curPage);
      }
    }
  }

  editDocType = id => {
    this.props.history.push(`documents_type/edit/${id}`);
  };

  confirm = id => {
    this.setState({isOpen: true, id});
  };

  deleteType = () => {
    const {searchWord, searchCurPage, curPage} = this.state;

    if (searchWord) {
      this.props.deleteDocType(this.state.id, searchCurPage, page => searchDocType(searchWord, page));
    } else {
      this.props.deleteDocType(this.state.id, curPage);
    }
    this.setState({isOpen: false});
  };


  newDocType = () => {
    this.props.history.push('documents_type/new_document_type');
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  viewDocType = id => {
    this.props.history.push(`documents_type/view_document_type/${id}`);
  };

  onSearchWord = (word) => {
    if (word.length >= 3) {
      this.props.searchWord(word, 1);
      this.setState({searchCurPage: 1, searchWord: word});
    } else {
      this.setState({searchCurPage: 1, searchWord: null});
    }
  };

  selectTranslation(kz, ru) {
    switch (this.props.lang) {
      case 'KZ_kz':
        return kz;
      default:
        return ru;
    }
  }

  onPageSwitch = curPage => {
    if (this.state.searchWord) {
      this.setState({searchCurPage: curPage});
    } else {
      this.setState({curPage});
    }
  };

  render() {
    const {isLoading, docTypes, isSearching, foundTypes} = this.props;

    return (
      <Fragment>
        <h3>{translate('documentTypeManagement.title')}</h3>
        <Button onClick={this.newDocType} variant="contained"
                color="primary">{translate('documentTypeManagement.button')}</Button>
        <SimpleSearchBox placeholder={translate('documentTypeManagement.searchBoxPlaceholder')}
                         selectChangeHandler={event => this.onSearchWord(event.target.value)}
                         isLoading={isSearching}
                         className="top-right_search-box"
                         icon="block"
        />
        <DataTable
          onPageSwitch={this.onPageSwitch}
          curPage={this.state.searchWord ? this.state.searchCurPage : this.state.curPage}
          numPages={this.state.searchWord ? this.props.searchPagesCount : this.props.pagesCount}
          rowsPerPage={this.state.searchWord ? this.props.searchPageSize : this.props.pageSize}
          isLoading={this.state.searchWord ? isSearching : isLoading}
          rows={(this.state.searchWord ? foundTypes : docTypes)
            .map(type => {
              return {
                id: type.id,
                cols: [
                  {value: () => this.selectTranslation(type.kkName, type.ruName), cb: () => this.viewDocType(type.id)},
                  {value: () => translate('documentTypeManagement.edit'), cb: () => this.editDocType(type.id)},
                  {value: () => translate('documentTypeManagement.delete'), cb: () => this.confirm(type.id)},
                ]
              };
            })
          }
        />
        <ConfirmationWindow isOpen={this.state.isOpen}
                            handleClose={this.handleClose}
                            delete={this.deleteType}/>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  pagesCount: state.docTypeManagement.pagesCount,
  pageSize: state.docTypeManagement.pageSize,
  docTypes: state.docTypeManagement.docTypes,
  isLoading: state.docTypeManagement.isLoading,
  searchPagesCount: state.search.pagesCount,
  searchPageSize: state.search.pageSize,
  foundTypes: state.search.result,
  isSearching: state.search.isLoading
});

const mapDispatchToProps = dispatch => ({
  fetchDocTypes: page => dispatch(fetchDocTypes(page)),
  deleteDocType: (id, page, refetch) => dispatch(deleteDocType(id, page, refetch)),
  searchWord: (word, page) => dispatch(searchDocType(word, page))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocTypeManagement);
