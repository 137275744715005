import {CHANGE_LANGUAGE} from "../actionTypes";

const initialState = {
  language: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {...state, language: action.language};
    default:
      return state;
  }
};

export default reducer;