import {CHANGE_LANGUAGE} from "../actionTypes";
import i18next from 'i18next';

export const changeLanguage = (langFunction) => {
  return dispatch => {
    dispatch({
      type: CHANGE_LANGUAGE,
      language: langFunction
    });
  }
};

export const setLanguage = (language) => {
  return dispatch => {
    i18next.init({
      lng: language,
      resources: require(`../../localization/${language}.js`)
    });
    dispatch(changeLanguage(i18next));
  }
};