import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {translate} from "../../localization/i18n";
import cloneDeep from 'lodash/cloneDeep';
import {NotificationManager} from 'react-notifications';
import {
  TextField,
  Button,
  LinearProgress,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import axios from '../../axios-api';
import CloudUploadIcon from '@material-ui/icons/CloudUploadSharp';
import {fetchCatalog, fetchCatalogs} from "../../store/actions/catalogManagement";
import {fetchDocType, fetchDocTypes} from "../../store/actions/docTypeManagement";
import {addDoc, cleanDoc, fetchDocument} from "../../store/actions/workWithDocs";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import {calendarToDate, dateToCalendar} from "../../components/Utils";
import './EditDocument.css';

class EditDocument extends Component {
  state = {
    catalog: '',
    documentType: '',
    signature: '',
    file: '',
    binaryData: '',
    fieldsMeta: [],
    foundCatalogs: [],
    foundDocTypes: [],
    signerInn: ''
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang ||
      (prevState.documentType && prevState.documentType !== this.state.documentType)) {
      this.forceUpdate();
    }

    const {catalog, documentType, document} = this.props;

    if (!this.state.catalog && catalog.id) {
      this.setState({catalog: {kkName: catalog.kkName, ruName: catalog.ruName, value: catalog.id}});
    }

    if (!this.state.documentType && documentType.id && document.id) {
      const fieldsMeta = [];
      document.data.forEach(doc => {
        documentType.fieldsMeta.forEach(type => {
          if (type.fieldCode === doc.fieldCode) {
            const obj = {...type};
            if (Array.isArray(doc.fieldValue) && doc.fieldValue.length === 0) {
              obj.fieldValue = [''];
            } else {
              obj.fieldValue = doc.fieldValue;
            }
            fieldsMeta.push(obj);
            this.setState({
              documentType: {
                kkName: documentType.kkName,
                ruName: documentType.ruName,
                value: documentType.id
              },
              fieldsMeta
            });
          }
        });
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanDoc();
  }

  componentDidMount() {
    const {dirID, typeID, id} = this.props.match.params;
    this.props.fetchDocTypes(1);
    this.props.fetchCatalogs(1);
    this.props.getDocument(dirID, id);
    this.props.getDocumentType(typeID);
    this.props.getCatalog(dirID);
  }

  signXmlBack = result => {
    if (result['code'] === "500") {
      NotificationManager.info(translate('editDoc.subscribeCanceled'));
      console.log(result);
    } else if (result['code'] === "200") {
      const signature = result['responseObject'];
      this.setState({signature});
      this.webSocket.close();
    }
  };

  getSignString = () => {
    const self = this;
    this.webSocket = new WebSocket('wss://127.0.0.1:13579/');
    this.webSocket.onmessage = function (event) {
      const result = JSON.parse(event.data);
      if (result != null) {
        const rw = {
          code: result['code'],
          message: result['message'],
          responseObject: result['responseObject']
        };
        self.signXmlBack(rw);
      }
    };
    this.webSocket.onopen = function () {
      axios.get(`/directory/${self.props.match.params.dirID}/document/${self.props.match.params.id}/getSignString`).then(response => {
        const signXml = {
          "module": "kz.gov.pki.knca.commonUtils",
          "method": "signXml",
          "args": ["PKCS12", "SIGNATURE", response.data, "", ""]
        };
        this.send(JSON.stringify(signXml));
      }, () => {
        NotificationManager.error(translate('editDoc.cantGetXml'));
      });
    };
    this.webSocket.onerror = function () {
      NotificationManager.error(translate('editDoc.ncalayerError'));
    };
  };

  fileChangeHandler = event => {
    if (event.target.files[0]) {
      this.setState({[event.target.name]: event.target.files[0]});
    }
  };

  metaChangeHandler = (event, id) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[id].fieldValue = event.target.value;
    this.setState({fieldsMeta});
  };

  submitFormHandler = event => {
    event.preventDefault();
    const self = this;
    const {file, documentType, signature, fieldsMeta, catalog} = this.state;
    const {addDoc, document} = this.props;
    let data = fieldsMeta.map(field => {
      function maybeConvertDate(value) {
        return field.fieldType.dataType === 'date' ? calendarToDate(value) : value;
      }

      if (Array.isArray(field.fieldValue)) {
        if (field.fieldType.dataType === 'boolean') {
          return {
            fieldCode: field.fieldCode,
            fieldValue: field.fieldValue
          };
        } else {
          return {
            fieldCode: field.fieldCode,
            fieldValue: field.fieldValue.map(value => maybeConvertDate(value)).filter(value => value)
          };
        }
      } else {
        return {
          fieldCode: field.fieldCode,
          fieldValue: maybeConvertDate(field.fieldValue)
        };
      }
    });

    data = data.filter(field => {
      if (typeof field.fieldValue === 'boolean') {
        return true;
      } else {
        return field.fieldValue;
      }
    });

    const docType = documentType.value;
    const dirID = catalog.value;
    const edc = signature ? signature : document.signature;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64result = reader.result.split(',')[1];
        const formData = {
          documentType: docType,
          signature: edc,
          fileName: file.name,
          binaryData: base64result,
          data,
          id: self.props.match.params.id
        };
        addDoc(formData, dirID);
      };
    } else {
      const formData = {
        documentType: docType,
        signature: edc,
        fileName: document.fileName,
        binaryData: document.binaryData,
        data,
        id: self.props.match.params.id
      };
      addDoc(formData, dirID);
    }
  };

  metaCheckBoxHandler = (event, id) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[id].fieldValue = !fieldsMeta[id].fieldValue;
    this.setState({fieldsMeta});
  };

  add = (id, boolean) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    if (boolean) {
      fieldsMeta[id].fieldValue.push(false);
    } else {
      fieldsMeta[id].fieldValue.push('');
    }
    this.setState({fieldsMeta});
  };

  remove = id => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[id].fieldValue.splice(-1, 1);
    this.setState({fieldsMeta});
  };

  metaFieldChangeHandler = (event, id, key) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[key].fieldValue[id] = event.target.value;
    this.setState({fieldsMeta});
  };

  metaFieldCheckBoxHandler = (event, id, key) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[key].fieldValue[id] = !fieldsMeta[key].fieldValue[id];
    this.setState({fieldsMeta});
  };

  onType = (value, event) => {
    if (value.length > 2) {
      switch (event) {
        case 'catalog':
          axios.post('/directory/find', {name: value, page: 1, pageSize: 50})
            .then(response => {
                if (response.data.status === 1) {
                  this.setState({foundCatalogs: response.data.object.data});
                }
              },
              error => {
                console.log(error);
              });
          break;
        case 'docType':
          axios.post('/documentType/find', {name: value, page: 1, pageSize: 50})
            .then(response => {
                if (response.data.status === 1) {
                  this.setState({foundDocTypes: response.data.object.data});
                }
              },
              error => {
                console.log(error);
              });
          break;
        default:
          break;
      }
    }
  };

  onSelect = (value, event) => {
    switch (event) {
      case 'catalog':
        if (value.label) {
          this.setState({catalog: value});
        }
        break;
      case 'docType':
        if (value.fieldsMeta) {
          const fieldsMeta = value.fieldsMeta.map(meta => {
            if (isRecurrent(meta.fieldType.recurrent)) {
              if (meta.fieldType.dataType === 'boolean') {
                return {...meta, fieldValue: [false]}
              } else {
                return {...meta, fieldValue: ['']};
              }
            } else {
              if (meta.fieldType.dataType === 'boolean') {
                return {...meta, fieldValue: false};
              } else {
                return {...meta, fieldValue: ''};
              }
            }
          });
          delete value.fieldsMeta;
          this.setState({documentType: value, fieldsMeta});
        }
        break;
      default:
        break;
    }
  };

  render() {
    const {docTypes, lang, catalogs, document} = this.props;
    const {isDocLoading, isDocTypeLoading, isCatalogLoading, isFetchingDocTypes, isFetchingCatalogs} = this.props;
    const foundCatalogs = this.state.foundCatalogs.length > 0 ? this.state.foundCatalogs : null;
    const foundDocTypes = this.state.foundDocTypes.length > 0 ? this.state.foundDocTypes : null;
    let metaLang, typeLang, isLoading;
    if (lang === 'RU_ru') {
      metaLang = 'ruFieldName';
      typeLang = 'ruName';
    } else {
      metaLang = 'kkFieldName';
      typeLang = 'kkName';
    }
    isLoading = isDocLoading || isDocTypeLoading || isCatalogLoading || isFetchingCatalogs || isFetchingDocTypes;
    return (
      <Fragment>
        <h3>{translate('editDoc.title')}</h3>
        {isLoading ? <LinearProgress className="preLoader"/> :
          <form className="new-doc-type_form-container" onSubmit={this.submitFormHandler} autoComplete="off">
            <ReactSelect placeholder={translate('newDoc.catalog')}
                         suggestions={(foundCatalogs || catalogs).map(cat => ({
                           label: cat[typeLang],
                           value: cat.id,
                           ruName: cat.ruName,
                           kkName: cat.kkName
                         }))}
                         value={{label: this.state.catalog[typeLang]}}
                         onType={value => this.onType(value, 'catalog')}
                         onSelect={value => this.onSelect(value, 'catalog')}
                         noOptionsMessage={() => translate('search.noMessage')}
            />
            <ReactSelect placeholder={translate('newDoc.docType')}
                         suggestions={(foundDocTypes || docTypes).map(type => ({
                           label: type[typeLang],
                           value: type.id,
                           ruName: type.ruName,
                           kkName: type.kkName,
                           fieldsMeta: type.fieldsMeta
                         }))}
                         value={{label: this.state.documentType[typeLang]}}
                         onType={value => this.onType(value, 'docType')}
                         onSelect={value => this.onSelect(value, 'docType')}
                         noOptionsMessage={() => translate('search.noMessage')}
            />
            <div className="new-doc_signature-section">
              <TextField
                label={translate('newDoc.edc')}
                margin="dense"
                variant="outlined"
                name="signature"
                value={this.state.signature ? this.state.signature : document.signature}
                fullWidth
                rows="4"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  readOnly: true
                }}
                multiline
              />
              <Button variant="contained" style={{height: '48px'}}
                      onClick={this.getSignString}>{translate('editDoc.subscribe')}</Button>
            </div>
            <div className="new-doc_upload-section">
              <TextField
                label={translate('newDoc.upload')}
                margin="dense"
                variant="outlined"
                value={this.state.file.name ? this.state.file.name : document.fileName}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true
                }}
                required
                fullWidth
              />
              <input
                id="contained-button-file"
                type="file"
                onChange={this.fileChangeHandler}
                name="file"
                style={{display: 'none'}}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                  <CloudUploadIcon/>
                </Button>
              </label>
            </div>
            <h4 className="new-doc-type_meta-title">{translate('newDoc.metaTitle')}:</h4>
            <hr/>
            {this.state.fieldsMeta.map((meta, key) =>
              meta.fieldType.dataType === 'boolean' ?
                <div className="new-doc_meta-section-container" key={key}>
                  {isRecurrent(meta.fieldType.recurrent) ?
                    <Fragment>
                      {meta.fieldValue.map((field, id) =>
                        <Fragment key={id}>
                          <FormControlLabel
                            checked={field}
                            control={
                              <Checkbox
                                onChange={event => this.metaFieldCheckBoxHandler(event, id, key)}
                                color="primary"
                                name="value"
                                required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
                              />
                            }
                            label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                          />
                        </Fragment>
                      )}
                      <Button variant="fab" mini color="primary" aria-label="Add"
                              onClick={() => this.add(key, 'boolean')}>+</Button>
                      {meta.fieldValue.length > 1 &&
                      <Button variant="fab" mini color="secondary" aria-label="Delete" style={{right: '30px'}}
                              onClick={() => this.remove(key)}>-</Button>}
                    </Fragment>
                    :
                    <FormControlLabel
                      checked={meta.fieldValue}
                      control={
                        <Checkbox
                          onChange={event => this.metaCheckBoxHandler(event, key)}
                          color="primary"
                          name="required"
                          required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
                        />
                      }
                      label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                    />
                  }
                </div>
                :
                <div className="new-doc_meta-section-container" key={key}>
                  {isRecurrent(meta.fieldType.recurrent) ?
                    <Fragment>
                      {meta.fieldValue.map((field, id) =>
                        <TextField
                          key={id}
                          margin="dense"
                          variant="outlined"
                          name="fieldValue"
                          type={meta.fieldType.dataType}
                          label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                          value={meta.fieldType.dataType === 'date' ? dateToCalendar(field) : field}
                          onChange={e => this.metaFieldChangeHandler(e, id, key)}
                          required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      <Button variant="fab" mini color="primary" aria-label="Add"
                              onClick={() => this.add(key)}>+</Button>
                      {meta.fieldValue.length > 1 &&
                      <Button variant="fab" mini color="secondary" aria-label="Delete" style={{right: '30px'}}
                              onClick={() => this.remove(key)}>-</Button>}
                    </Fragment>
                    :
                    <TextField
                      margin="dense"
                      variant="outlined"
                      name="fieldValue"
                      type={meta.fieldType.dataType}
                      label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                      value={meta.fieldType.dataType === 'date' ? dateToCalendar(meta.fieldValue) : meta.fieldValue}
                      onChange={e => this.metaChangeHandler(e, key)}
                      required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  }

                </div>
            )}
            <Button variant="contained" color="primary" className="new-doc-type_submit-btn"
                    type="submit">{translate('editDoc.saveChanges')}</Button>
          </form>
        }
      </Fragment>
    );
  }
}

function isRecurrent(recurrent) {
  if (typeof recurrent === 'string') {
    return JSON.parse(recurrent);
  } else {
    return recurrent;
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  docTypes: state.docTypeManagement.docTypes,
  catalogs: state.catalogManagement.catalogs,
  isFetchingDocTypes: state.docTypeManagement.isLoading,
  isFetchingCatalogs: state.catalogManagement.isLoading,
  document: state.workWithDocs.document,
  documentType: state.docTypeManagement.docType,
  catalog: state.catalogManagement.catalog,
  isDocLoading: state.workWithDocs.isLoading,
  isDocTypeLoading: state.docTypeManagement.isLoading,
  isCatalogLoading: state.catalogManagement.isLoading
});

const mapDispatchToProps = dispatch => ({
  fetchDocTypes: page => dispatch(fetchDocTypes(page)),
  fetchCatalogs: page => dispatch(fetchCatalogs(page)),
  addDoc: (formData, dirID) => dispatch(addDoc(formData, dirID)),
  getDocument: (dirID, docID) => dispatch(fetchDocument(dirID, docID)),
  getDocumentType: id => dispatch(fetchDocType(id)),
  getCatalog: id => dispatch(fetchCatalog(id)),
  cleanDoc: () => dispatch(cleanDoc())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDocument);
