import React from 'react';
import './ChangeLanguage.css';
import ru_flag from '../../assets/ru.jpg';
import kz_flag from '../../assets/kz.png';

const ChangeLanguage = props => {
  const isRU = props.lang === 'RU_ru';
  const isKZ = props.lang === 'KZ_kz';
  return (
    <div className="language_buttons-container">
      <img src={kz_flag} alt="KZ" className={isKZ ? 'active' : ''} onClick={() => props.setLanguage('KZ_kz')}/>
      <img src={ru_flag} alt="RU" className={isRU ? 'active' : ''} onClick={() => props.setLanguage('RU_ru')}/>
    </div>
  )
};

export default ChangeLanguage;