import axios, {apiURL} from '../../axios-api';
import {NotificationManager} from 'react-notifications';
import {translate, translateCombinedError} from "../../localization/i18n";
import {push} from 'react-router-redux';
import AXIOS from 'axios';

import {
  AUTH_LOGIN, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE, AUTH_LOGOUT, UPDATE_AUTH_STATE, UPDATE_TOKEN_FAILURE
} from "../actionTypes";

function authLogin_() {
  return {type: AUTH_LOGIN};
}

function authLoginSuccess_(authInfo) {
  return {type: AUTH_LOGIN_SUCCESS, authInfo};
}

function authLoginFailure_(error) {
  return {type: AUTH_LOGIN_FAILURE, error};
}

export function authLogin(credentials) {
  return dispatch => {
    dispatch(authLogin_());

    axios.post(`/login`, credentials)
      .then(response => {
          if (response.data.status === "SUCCESS") {
            NotificationManager.success(translate('login.successMsg'));
            dispatch(authLoginSuccess_(response.data));
            dispatch(push('/catalogs'));
          } else {
            NotificationManager.error(translateCombinedError('login.errorMsg', response.data.status));
            dispatch(authLoginFailure_(response.data));
          }
        },
        error => {
          if (error.response) {
            NotificationManager.error(translateCombinedError('login.errorMsg', error.response.data.status));
          } else {
            NotificationManager.error(translate('login.errorMsg'));
          }
          dispatch(authLoginFailure_(error));
        });
  };
}

function userLogout() {
  return {type: AUTH_LOGOUT};
}

export function logout() {
  return dispatch => {
    dispatch(userLogout());
    localStorage.removeItem('state');
    dispatch(push('/login'));
  };
}

function startUpdateUserInfo() {
  NotificationManager.info(translate('login.updateToken'));
}

function updateUserInfoSuccess() {
  NotificationManager.success(translate('login.tokenFetched'));
}

function updateUserInfoFailure() {
  NotificationManager.error(translate('login.updateTokenFailure'));
  return {type: UPDATE_TOKEN_FAILURE};
}

function updateAuthState(userInfo) {
  return {type: UPDATE_AUTH_STATE, userInfo}
}

export function updateUserInfo(refreshToken) {
  startUpdateUserInfo();
  return dispatch => {
    const headers = {
      Authorization: `Bearer ${refreshToken}`
    };
    AXIOS.post(`${apiURL}token/refresh`, null, {headers})
      .then(response => {
        if (response.data.status === 'SUCCESS') {
          dispatch(updateAuthState(response.data));
          const auth = JSON.parse(localStorage.getItem('state'));
          auth.authInfo = response.data;
          localStorage.setItem('state', JSON.stringify(auth));
          updateUserInfoSuccess();
        } else {
          dispatch(updateUserInfoFailure());
          localStorage.removeItem('state');
        }
      }, error => {
        dispatch(updateUserInfoFailure());
        localStorage.removeItem('state');
      });
  }
}