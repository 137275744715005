import React, {Fragment, Component} from 'react';
import {connect} from "react-redux";
import {Button, TextField, CircularProgress} from '@material-ui/core';
import {translate} from "../../localization/i18n";
import {authLogin} from "../../store/actions/login";
import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }
  }

  onChangeValue = (name, value) => {
    this.setState({[name]: value});
  };

  onSubmit = event => {
    event.preventDefault();

    const credentials = {
      username: this.state.username,
      password: this.state.password,
    };

    this.props.authLogin(credentials);
  };

  render() {
    return (
      <Fragment>
        <form
          action="/"
          onSubmit={this.onSubmit}
          autoComplete="off">
          <div className="login_form-container">
            <div>
              <TextField
                label={translate('login.inputLogin')}
                name="username"
                margin="dense"
                variant="outlined"
                value={this.state.username}
                onChange={event => this.onChangeValue('username', event.target.value)}
                required
                fullWidth
              />
            </div>
            <div>
              <TextField
                label={translate('login.inputPassword')}
                type="password"
                name="password"
                margin="dense"
                variant="outlined"
                value={this.state.password}
                onChange={event => this.onChangeValue('password', event.target.value)}
                required
                fullWidth
              />
            </div>
            <Button
              className="login_submit-btn"
              type="submit"
              variant="contained"
              color="primary"
              disabled={this.props.isLogining}
            >
              {this.props.isLogining ? <CircularProgress color="primary" size={20}/> : translate('login.button')}
            </Button>
          </div>
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  authInfo: state.login.authInfo,
  isLogining: state.login.isLogining,
});

const mapDispatchToProps = dispatch => ({
  authLogin: credentials => dispatch(authLogin(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
