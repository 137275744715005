import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import LinearProgress from '@material-ui/core/LinearProgress';
import './DynamicDataTable.css';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const {classes, count, page, rowsPerPage, theme} = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {withTheme: true})(
  TablePaginationActions,
);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  }
});

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {header, order, orderBy} = this.props;

    return (
      <TableHead>
        <TableRow>
          {header.map((row, key) => {
            return (
              row.id === null ?
                <TableCell key={key} numeric={row.numeric}>{row.label}</TableCell>
                :
                <TableCell
                  key={row.id}
                  numeric={row.numeric}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  header: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string,
};

EnhancedTableHead.defaultProps = {
  order: 'asc'
};

const makeTableHeader = (header, {onRequestSort, order, orderBy}) => {
  return (
    orderBy !== null ? (
      <EnhancedTableHead
        header={header}
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
      />
    ) : (
      <TableHead>
        <TableRow>
          {header.map(({label, numeric = false}) => (<TableCell numeric={numeric}>{label}</TableCell>))}
        </TableRow>
      </TableHead>
    )
  );
};

const getValue = value => typeof value === 'function' ? value() : value;

const makeTableRow = cols => {
  return cols.map(({value, numeric = false, cb}, key) => {
    if (cb === undefined) {
      return <TableCell numeric={numeric} key={key}>{getValue(value)}</TableCell>;
    } else {
      return <TableCell numeric={numeric} key={key} className="table-cell-with-action"
                        onClick={cb}>{getValue(value)}</TableCell>;
    }
  });
};

function desc(a, b, index) {
  if (b.cols[index].value < a.cols[index].value) {
    return -1;
  }
  if (b.cols[index].value > a.cols[index].value) {
    return 1;
  }
  return 0;
}

function getSorting(header, order, orderBy) {
  const index = header.findIndex(({id}) => id === orderBy);

  return order === 'desc' ? (a, b) => desc(a, b, index) : (a, b) => -desc(a, b, index);
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const maybeSort = (header, rows, orderBy, order) => {
  if (orderBy === undefined || orderBy === null) return rows;

  return stableSort(rows, getSorting(header, order, orderBy));
};

class DynamicDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: props.rows,
      page: 0,
      rowsPerPage: 10,
      order: props.order,
      orderBy: props.orderBy,
    };
  }

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({order, orderBy});
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const stateRows = this.state.rows;
    const propsRows = this.props.rows;

    if (stateRows.length !== propsRows.length) {
      this.setState({rows: this.props.rows});
    }
  }

  render() {
    const {classes, header, isLoading} = this.props;
    const {order, orderBy, rowsPerPage, page, rows} = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {header.length > 0 && makeTableHeader(header,
              {onRequestSort: this.handleRequestSort, orderBy, order})}
            {isLoading ?
              <TableBody>
                <TableRow>
                  <TableCell>
                    <LinearProgress/>
                  </TableCell>
                </TableRow>
              </TableBody>
              :
              <TableBody>
                {maybeSort(header, rows, orderBy, order)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    return (
                      <TableRow key={row.id}>
                        {makeTableRow(row.cols)}
                      </TableRow>
                    );
                  })}
              </TableBody>}
            <TableFooter className="dynamic-data-table_footer">
              <TableRow>
                <TablePagination className="table-pagination"
                                 colSpan={3}
                                 count={rows.length}
                                 rowsPerPage={rowsPerPage}
                                 page={page}
                                 onChangePage={this.handleChangePage}
                                 ActionsComponent={TablePaginationActionsWrapped}
                                 rowsPerPageOptions={[]}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

DynamicDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.array,
  rows: PropTypes.array.isRequired,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  isLoading: PropTypes.bool
};

DynamicDataTable.defaultProps = {
  header: [],
  orderBy: null,
  order: 'asc'
};

export default withStyles(styles)(DynamicDataTable);