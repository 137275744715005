import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';
import store, {history} from './store/configureStore';
import axios from './axios-api';
import {updateUserInfo} from "./store/actions/login";

const TOKEN_HAS_EXPIRED = 'TOKEN_HAS_EXPIRED';

axios.interceptors.request.use(config => {
  try {
    config.headers['Authorization'] = `Bearer ${store.getState().login.authInfo.accessToken}`;
  } catch (e) {
    // do nothing
  }
  return config;
});

let canUpdateToken = true;

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  console.log(error.response);
  if (error.response && error.response.data.message === TOKEN_HAS_EXPIRED && canUpdateToken) {
    canUpdateToken = false;
    store.dispatch(updateUserInfo(store.getState().login.authInfo.refreshToken));
    setTimeout(function () {
      canUpdateToken = true;
    }, 120000);
  }
  return Promise.reject(error);
});

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App/>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

