import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Button} from "@material-ui/core";
import SimpleSearchBox from "../../components/SimpleSearchBox/SimpleSearchBox";
import DataTable from "../../components/DataTable/DataTable";
import ConfirmationWindow from "../../components/ConfirmationWindow/ConfirmationWindow";
import {translate} from "../../localization/i18n";
import {fetchCatalogs, deleteCatalog} from "../../store/actions/catalogManagement";
import {searchCatalog} from "../../store/actions/search";
import './CatalogManagement.css';

class CatalogManagement extends Component {
  state = {
    isOpen: false,
    id: null,
    curPage: 1,
    searchWord: null,
    searchCurPage: 1,
  };

  componentDidMount() {
    this.props.fetchCatalogs(this.state.curPage);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }

    const {curPage, searchCurPage, searchWord} = this.state;

    if (searchWord) {
      if (prevState.searchCurPage !== searchCurPage) {
        this.props.searchWord(searchWord, searchCurPage);
      }
    } else {
      if (prevState.curPage !== curPage) {
        this.props.fetchCatalogs(curPage);
      }
    }
  }

  editCatalog = id => {
    this.props.history.push(`catalogs/edit/${id}`);
  };

  deleteCatalog = () => {
    const {searchWord, searchCurPage, curPage} = this.state;

    if(searchWord) {
      this.props.deleteCatalog(this.state.id, searchCurPage, page => searchCatalog(searchWord, page));
    } else {
      this.props.deleteCatalog(this.state.id, curPage);
    }
    this.setState({isOpen: false});
  };

  confirm = id => {
    this.setState({isOpen: true, id});
  };

  newCatalog = () => {
    this.props.history.push('/catalogs/new_catalog');
  };

  viewCatalog = id => {
    this.props.history.push(`view_catalog/${id}`);
  };

  handleClose = () => {
    this.setState({isOpen: false});
  };

  onSearchWord = (word) => {
    if (word.length >= 3) {
      this.props.searchWord(word, 1);
      this.setState({searchCurPage: 1, searchWord: word});
    } else {
      this.setState({searchCurPage: 1, searchWord: null});
    }
  };

  selectTranslation(kz, ru) {
    switch (this.props.lang) {
      case 'KZ_kz':
        return kz;
      default:
        return ru;
    }
  }

  onPageSwitch = curPage => {
    if (this.state.searchWord) {
      this.setState({searchCurPage: curPage});
    } else {
      this.setState({curPage});
    }
  };

  render() {
    const {isLoading, catalogs, isSearching, foundCatalogs} = this.props;

    return (
      <Fragment>
        <h3>{translate('catalogManagement.title')}</h3>
        <Button
          onClick={this.newCatalog}
          variant="contained"
          color="primary">{translate('catalogManagement.button')}
        </Button>
        <SimpleSearchBox
          placeholder={translate('catalogManagement.searchBoxPlaceholder')}
          selectChangeHandler={event => this.onSearchWord(event.target.value)}
          isLoading={isSearching}
          className="top-right_search-box"
          icon="block"
        />
        <DataTable
          onPageSwitch={this.onPageSwitch}
          curPage={this.state.searchWord ? this.state.searchCurPage : this.state.curPage}
          numPages={this.state.searchWord ? this.props.searchPagesCount : this.props.pagesCount}
          rowsPerPage={this.state.searchWord ? this.props.searchPageSize : this.props.pageSize}
          isLoading={this.state.searchWord ? isSearching : isLoading}
          rows={(this.state.searchWord ? foundCatalogs : catalogs)
            .map(type => {
              return {
                id: type.id,
                cols: [
                  {value: () => this.selectTranslation(type.kkName, type.ruName), cb: () => this.viewCatalog(type.id)},
                  {value: () => translate('catalogManagement.edit'), cb: () => this.editCatalog(type.id)},
                  {value: () => translate('catalogManagement.delete'), cb: () => this.confirm(type.id)},
                ]
              };
            })
          }
        />
        <ConfirmationWindow
          isOpen={this.state.isOpen}
          handleClose={this.handleClose}
          delete={this.deleteCatalog}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  pagesCount: state.catalogManagement.pagesCount,
  pageSize: state.catalogManagement.pageSize,
  catalogs: state.catalogManagement.catalogs,
  isLoading: state.catalogManagement.isLoading,
  searchPagesCount: state.search.pagesCount,
  searchPageSize: state.search.pageSize,
  foundCatalogs: state.search.result,
  isSearching: state.search.isLoading
});

const mapDispatchToProps = dispatch => ({
  fetchCatalogs: page => dispatch(fetchCatalogs(page)),
  deleteCatalog: (id, page, refetch) => dispatch(deleteCatalog(id, page, refetch)),
  searchWord: (word, page) => dispatch(searchCatalog(word, page))
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogManagement);
