import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {LinearProgress, TextField, FormControlLabel, Checkbox} from '@material-ui/core';
import {translate} from "../../localization/i18n";
import {fetchDocType} from "../../store/actions/docTypeManagement";

class ViewDocumentType extends Component {
  componentDidMount() {
    this.props.fetchDocType(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }
  }

  render() {
    const {kkName, ruName, enName, kkDescription, ruDescription, enDescription, fieldsMeta} = this.props.docType;
    return (
      <Fragment>
        <h3>{translate('viewDocType.title')}</h3>
        {this.props.isLoading ? <LinearProgress className="preLoader"/> :
          <form>
            <div className="new-doc-type_form-container">
              <TextField
                label={translate('newDocType.kkName')}
                margin="dense"
                variant="outlined"
                name="kkName"
                value={kkName}
                InputProps={{
                  readOnly: true
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                label={translate('newDocType.ruName')}
                margin="dense"
                variant="outlined"
                name="ruName"
                InputProps={{
                  readOnly: true
                }}
                value={ruName}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                label={translate('newDocType.enName')}
                margin="dense"
                variant="outlined"
                name="enName"
                value={enName}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                label={translate('newDocType.kkDescription')}
                multiline
                rows="3"
                margin="dense"
                variant="outlined"
                name="kkDescription"
                value={kkDescription}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                label={translate('newDocType.ruDescription')}
                multiline
                rows="3"
                margin="dense"
                variant="outlined"
                name="ruDescription"
                value={ruDescription}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                label={translate('newDocType.enDescription')}
                multiline
                rows="3"
                margin="dense"
                variant="outlined"
                name="enDescription"
                value={enDescription}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
            <h4 className="meta-title">{translate('newDocType.metaTitle')}:</h4>
            <hr/>
            {(fieldsMeta || []).map((section, id) =>
              <div key={id} className="meta-section">
                <TextField
                  label={translate('newDocType.kkName')}
                  margin="dense"
                  variant="outlined"
                  name="kkFieldName"
                  value={section.kkFieldName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <TextField
                  label={translate('newDocType.ruName')}
                  margin="dense"
                  variant="outlined"
                  name="ruFieldName"
                  value={section.ruFieldName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <TextField
                  label={translate('newDocType.enName')}
                  margin="dense"
                  variant="outlined"
                  name="enFieldName"
                  value={section.enFieldName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <TextField
                  label={translate('newDocType.code')}
                  margin="dense"
                  variant="outlined"
                  name="fieldCode"
                  value={section.fieldCode}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <TextField
                  label={translate('newDocType.type')}
                  margin="dense"
                  variant="outlined"
                  name="fieldType"
                  value={section.fieldType.dataType}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <div className="meta-section_checkbox-container">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={typeof section.required === 'string' ? JSON.parse(section.required) : section.required}
                        color="primary"
                        name="required"
                        disabled
                      />
                    }
                    label={translate('newDocType.required')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={typeof section.fieldType.recurrent === 'string' ? JSON.parse(section.fieldType.recurrent) : section.fieldType.recurrent}
                        color="primary"
                        name="recurrent"
                        disabled
                      />
                    }
                    label={translate('newDocType.recurrent')}
                  />
                </div>
              </div>)}
          </form>}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  isLoading: state.docTypeManagement.isLoading,
  docType: state.docTypeManagement.docType
});

const mapDispatchToProps = dispatch => ({
  fetchDocType: id => dispatch(fetchDocType(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocumentType);