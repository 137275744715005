import {
  FETCH_DOCUMENT_FAILURE, FETCH_DOCUMENT_START, FETCH_DOCUMENT_SUCCESS,
  SEARCH_DOCUMENTS_START, SEARCH_DOCUMENTS_FAILURE, SEARCH_DOCUMENTS_SUCCESS,
  RESET_SEARCH_DOCS, RESET_DOCUMENT
} from "../actionTypes";

const initialState = {
  pagesCount: 0,
  pageSize: 0,
  foundDocs: [],
  searchingDocs: false,
  error: null,
  document: {},
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_DOCUMENTS_START:
      return {...state, searchingDocs: true, pagesCount: 0, pageSize: 0, foundDocs: []};
    case SEARCH_DOCUMENTS_SUCCESS:
      return {...state, searchingDocs: false, pagesCount: action.pagesCount, pageSize: action.pageSize, foundDocs: action.foundDocs};
    case SEARCH_DOCUMENTS_FAILURE:
      return {...state, searchingDocs: false, error: action.error};

    case FETCH_DOCUMENT_START:
      return {...state, isLoading: true};
    case FETCH_DOCUMENT_SUCCESS:
      return {...state, isLoading: false, document: action.document};
    case FETCH_DOCUMENT_FAILURE:
      return {...state, isLoading: false, error: action.error};

    case RESET_SEARCH_DOCS:
      return {...state, searchingDocs: false, error: null,  pagesCount: 0, pageSize: 0, foundDocs: []};
    case RESET_DOCUMENT:
      return {...state, document: {}};

    default:
      return state;
  }
};

export default reducer;