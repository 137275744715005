import axios from '../../axios-api';
import {NotificationManager} from 'react-notifications';
import {
  ADD_DOCUMENT, ADD_DOCUMENT_FAILURE, ADD_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE, DELETE_DOCUMENT_START, DELETE_DOCUMENT_SUCCESS,
  FETCH_DOCUMENT_FAILURE, FETCH_DOCUMENT_START, FETCH_DOCUMENT_SUCCESS,
  SEARCH_DOCUMENTS_START, SEARCH_DOCUMENTS_FAILURE, SEARCH_DOCUMENTS_SUCCESS,
  RESET_SEARCH_DOCS, RESET_DOCUMENT, RESET_CATALOG, RESET_DOC_TYPE
} from "../actionTypes";
import {translate, translateError, translateCombinedError} from "../../localization/i18n";

function addDocument() {
  return {type: ADD_DOCUMENT};
}

function addDocumentSuccess() {
  return {type: ADD_DOCUMENT_SUCCESS};
}

function addDocumentFailure(error) {
  return {type: ADD_DOCUMENT_FAILURE, error};
}

export function addDoc(formData, directoryID) {
  return dispatch => {
    dispatch(addDocument());
    axios.post(`/directory/${directoryID}/document`, formData).then(response => {
      if (response.data.status === 1) {
        NotificationManager.success(translate('newDoc.successMessage'));
        dispatch(addDocumentSuccess());
      } else {
        NotificationManager.error(translateCombinedError('newDoc.errorMessage', response.data.message));
        dispatch(addDocumentFailure(response.data));
      }
    }, error => {
      if (error.response) {
        NotificationManager.error(translateCombinedError('newDoc.errorMessage', error.response.data.message));
      } else {
        NotificationManager.error(translate('newDoc.errorMessage'));
      }
      dispatch(addDocumentFailure(error));
    });
  };
}

function searchDocumentsStart() {
  return {type: SEARCH_DOCUMENTS_START};
}

function searchDocumentsSuccess(pagesCount, pageSize, foundDocs) {
  return {type: SEARCH_DOCUMENTS_SUCCESS, pagesCount, pageSize, foundDocs};
}

function searchDocumentsFailure(error) {
  return {type: SEARCH_DOCUMENTS_FAILURE, error};
}

export function searchDocs(page, data) {
  return dispatch => {
    dispatch(searchDocumentsStart());

    axios.post(`/document/search?page=${page}`, data)
      .then(response => {
          if (response.data.status === 1) {
            dispatch(searchDocumentsSuccess(response.data.object.pagesCount, response.data.object.pageSize, response.data.object.data));
          } else {
            dispatch(searchDocumentsFailure(response.data));
          }
        },
        error => {
          dispatch(searchDocumentsFailure(error));
        });
  };
}

function deleteDocumentStart() {
  return {type: DELETE_DOCUMENT_START};
}

function deleteDocumentSuccess(id) {
  return {type: DELETE_DOCUMENT_SUCCESS, id};
}

function deleteDocumentFailure() {
  return {type: DELETE_DOCUMENT_FAILURE};
}

export function deleteDocument(dirID, docID, page, data) {
  return dispatch => {
    dispatch(deleteDocumentStart());

    axios.delete(`/directory/${dirID}/document/${docID}`)
      .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success(translate('workWithDocs.successDeleted'));
            dispatch(deleteDocumentSuccess(response.data.object.id));
            dispatch(searchDocs(page, data))
          }
          else {
            NotificationManager.error(translateError(response.data.message));
            dispatch(deleteDocumentFailure());
          }
        },
        error => {
          if (error.response) {
            NotificationManager.error(translateError(error.response.data.message));
          }
          dispatch(deleteDocumentFailure());
        });
  };
}

function fetchDocumentStart() {
  return {type: FETCH_DOCUMENT_START};
}

function fetchDocumentSuccess(document) {
  return {type: FETCH_DOCUMENT_SUCCESS, document};
}

function fetchDocumentFailure(error) {
  return {type: FETCH_DOCUMENT_FAILURE, error};
}

export function fetchDocument(dirID, docID) {
  return dispatch => {
    dispatch(fetchDocumentStart());
    axios.get(`/directory/${dirID}/document/${docID}?getBinaryData=true`).then(response => {
      if (response.data.status === 1) {
        dispatch(fetchDocumentSuccess(response.data.object));
      } else {
        dispatch(fetchDocumentFailure(response.data));
      }
    }, error => {
      dispatch(fetchDocumentFailure(error));
    });
  };
}

const resetSearchDocs_ = () =>
  ({type: RESET_SEARCH_DOCS});

export const resetSearchDocs = () => dispatch => {
  dispatch(resetSearchDocs_());
};

export function cleanDoc() {
  return dispatch => {
    dispatch({type: RESET_DOCUMENT});
    dispatch({type: RESET_CATALOG});
    dispatch({type: RESET_DOC_TYPE});
  }
}
