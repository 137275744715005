import thunkMiddleware from "redux-thunk";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {routerMiddleware, routerReducer} from "react-router-redux";
import createHistory from "history/createBrowserHistory";

import login from './reducers/login';
import docTypeManagement from './reducers/docTypeManagement';
import catalogManagement from './reducers/catalogManagement';
import workWithDocs from './reducers/workWithDocs';
import search from './reducers/search';
import localization from "./reducers/localization";
import {saveState, loadState} from "./localStorage";

const rootReducer = combineReducers({
  login: login,
  docTypeManagement: docTypeManagement,
  catalogManagement: catalogManagement,
  workWithDocs: workWithDocs,
  search: search,
  localization: localization,
  routing: routerReducer
});

export const history = createHistory();

const middleware = [
  thunkMiddleware,
  routerMiddleware(history)
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(applyMiddleware(...middleware));

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
  saveState({
    login: store.getState().login
  });
});

export default store;