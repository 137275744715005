import React, {Fragment} from 'react';
import {NotificationContainer} from 'react-notifications';
import {List, ListItem, Grid, Divider, Card, CardContent} from '@material-ui/core';
import 'react-notifications/lib/notifications.css';
import './Layout.css';
import {translate} from "../../localization/i18n";
import {NavLink} from "react-router-dom";
import ChangeLanguage from "../../components/ChangeLanguage/ChangeLanguage";

const Layout = props => (
  <Grid container className="layout">
    <NotificationContainer/>
    <Grid item className="layout-navigation">
      <List component="nav">
        <Divider/>
        {!props.user ?
          <NavLink to="/login" className="layout-navigation-link">
            <ListItem button divider>
              {translate('login.title')}
            </ListItem>
          </NavLink> :
          <Fragment>
            <NavLink to="/catalogs" className="layout-navigation-link">
              <ListItem button divider>
                {translate('layout.catalogManagement')}
              </ListItem>
            </NavLink>
            <NavLink to="/documents_type" className="layout-navigation-link">
              <ListItem button divider>
                {translate('layout.docTypeManagement')}
              </ListItem>
            </NavLink>
            <NavLink to="/documents_list" className="layout-navigation-link">
              <ListItem button divider>
                {translate('layout.workWithDocs')}
              </ListItem>
            </NavLink>
            <NavLink to="/search" className="layout-navigation-link">
              <ListItem button divider>
                {translate('layout.search')}
              </ListItem>
            </NavLink>
            <ListItem button divider onClick={props.logout}>
              {translate('login.logout')}
            </ListItem>
          </Fragment>
        }
      </List>
    </Grid>
    <Grid item xs={9}>
      <Card className="container">
        <CardContent>
          <ChangeLanguage setLanguage={props.setLanguage} lang={props.lang}/>
          {props.children}
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default Layout;
