import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {translate} from "../../localization/i18n";
import cloneDeep from 'lodash/cloneDeep';
import {NotificationManager} from 'react-notifications';
import {
  TextField,
  Button,
  LinearProgress,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import axios from '../../axios-api';
import CloudUploadIcon from '@material-ui/icons/CloudUploadSharp';
import {fetchCatalogs} from "../../store/actions/catalogManagement";
import {fetchDocTypes} from "../../store/actions/docTypeManagement";
import {addDoc} from "../../store/actions/workWithDocs";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import './NewDocument.css';
import {calendarToDate} from "../../components/Utils";

class NewDocument extends Component {
  state = {
    catalogID: '',
    documentType: '',
    signature: '',
    file: '',
    fieldsMeta: [],
    foundCatalogs: [],
    foundDocTypes: []
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang ||
      (prevState.documentType && prevState.documentType !== this.state.documentType)) {
      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.props.fetchDocTypes(1);
    this.props.fetchCatalogs(1);
  }

  fileChangeHandler = event => {
    if (event.target.files[0]) {
      this.setState({[event.target.name]: event.target.files[0]});
    }
  };

  metaChangeHandler = (event, id) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[id].fieldValue = event.target.value;
    this.setState({fieldsMeta});
  };

  submitFormHandler = event => {
    event.preventDefault();
    const {file, documentType, signature, fieldsMeta, catalogID} = this.state;
    const {addDoc} = this.props;
    if (!catalogID) {
      NotificationManager.error(translate('newDoc.chooseCatalogError'));
    } else if (!documentType) {
      NotificationManager.error(translate('newDoc.chooseTypeError'));
    } else {
      let data = fieldsMeta.map(field => {
        function maybeConvertDate(value) {
          return field.fieldType.dataType === 'date' ? calendarToDate(value) : value;
        }

        if (Array.isArray(field.fieldValue)) {
          if (field.fieldType.dataType === 'boolean') {
            return {
              fieldCode: field.fieldCode,
              fieldValue: field.fieldValue
            };
          } else {
            return {
              fieldCode: field.fieldCode,
              fieldValue: field.fieldValue.map(value => maybeConvertDate(value)).filter(value => value)
            };
          }
        } else {
          return {
            fieldCode: field.fieldCode,
            fieldValue: maybeConvertDate(field.fieldValue)
          };
        }
      });

      data = data.filter(field => {
        if (typeof field.fieldValue === 'boolean') {
          return true;
        } else {
          return field.fieldValue;
        }
      });

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64result = reader.result.split(',')[1];
        const formData = {
          documentType,
          signature,
          fileName: file.name,
          binaryData: base64result,
          data
        };
        addDoc(formData, catalogID);
      };
    }
  };

  metaCheckBoxHandler = (event, id) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[id].fieldValue = !fieldsMeta[id].fieldValue;
    this.setState({fieldsMeta});
  };

  add = (id, boolean) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    if (boolean) {
      fieldsMeta[id].fieldValue.push(false);
    } else {
      fieldsMeta[id].fieldValue.push('');
    }
    this.setState({fieldsMeta});
  };

  remove = id => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[id].fieldValue.splice(-1, 1);
    this.setState({fieldsMeta});
  };

  metaFieldChangeHandler = (event, id, key) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[key].fieldValue[id] = event.target.value;
    this.setState({fieldsMeta});
  };

  metaFieldCheckBoxHandler = (event, id, key) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    fieldsMeta[key].fieldValue[id] = !fieldsMeta[key].fieldValue[id];
    this.setState({fieldsMeta});
  };

  onType = (value, event) => {
    switch (event) {
      case 'catalog':
        axios.post('/directory/find', {name: value, page: 1, pageSize: 50})
          .then(response => {
              if (response.data.status === 1) {
                this.setState({foundCatalogs: response.data.object.data});
              }
            },
            error => {
              console.log(error);
            });
        break;
      case 'docType':
        axios.post('/documentType/find', {name: value, page: 1, pageSize: 50})
          .then(response => {
              if (response.data.status === 1) {
                this.setState({foundDocTypes: response.data.object.data});
              }
            },
            error => {
              console.log(error);
            });
        break;
      default:
        break;
    }
  };

  onSelect = (value, event) => {
    switch (event) {
      case 'catalog':
        this.setState({catalogID: value.value});
        break;
      case 'docType':
        if (value.fieldsMeta) {
          const fieldsMeta = value.fieldsMeta.map(meta => {
            if (isRecurrent(meta.fieldType.recurrent)) {
              if (meta.fieldType.dataType === 'boolean') {
                return {...meta, fieldValue: [false]}
              } else {
                return {...meta, fieldValue: ['']};
              }
            } else {
              if (meta.fieldType.dataType === 'boolean') {
                return {...meta, fieldValue: false};
              } else {
                return {...meta, fieldValue: ''};
              }
            }
          });
          this.setState({documentType: value.value, fieldsMeta});
        }
        break;
      default:
        break;
    }
  };

  render() {
    const {isFetchingDocTypes, docTypes, lang, isFetchingCatalogs, catalogs} = this.props;
    const foundCatalogs = this.state.foundCatalogs.length > 0 ? this.state.foundCatalogs : null;
    const foundDocTypes = this.state.foundDocTypes.length > 0 ? this.state.foundDocTypes : null;

    let metaLang;
    if (lang === 'RU_ru') {
      metaLang = 'ruFieldName';
    } else {
      metaLang = 'kkFieldName';
    }

    return (
      <Fragment>
        <h3>{translate('newDoc.title')}</h3>
        {(isFetchingDocTypes || isFetchingCatalogs) ? <LinearProgress className="preLoader"/> :
          <form className="new-doc-type_form-container" onSubmit={this.submitFormHandler} autoComplete="off">
            <ReactSelect placeholder={translate('newDoc.catalog')}
                         suggestions={(foundCatalogs || catalogs).map(catalog => ({
                           label: catalog.ruName,
                           value: catalog.id
                         }))}
                         onType={value => this.onType(value, 'catalog')}
                         onSelect={value => this.onSelect(value, 'catalog')}
            />
            <ReactSelect placeholder={translate('newDoc.docType')}
                         suggestions={(foundDocTypes || docTypes).map(type => ({
                           label: type.ruName,
                           value: type.id,
                           fieldsMeta: type.fieldsMeta
                         }))}
                         onType={value => this.onType(value, 'docType')}
                         onSelect={value => this.onSelect(value, 'docType')}
            />
            {/*<TextField
              label={translate('newDoc.edc')}
              margin="dense"
              variant="outlined"
              name="signature"
              value={this.state.signature}
              onChange={this.inputChangeHandler}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />*/}
            <div className="new-doc_upload-section">
              <TextField
                label={translate('newDoc.upload')}
                margin="dense"
                variant="outlined"
                value={this.state.file.name ? this.state.file.name : ''}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                fullWidth
              />
              <input
                id="contained-button-file"
                type="file"
                onChange={this.fileChangeHandler}
                name="file"
                style={{display: 'none'}}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                  <CloudUploadIcon/>
                </Button>
              </label>
            </div>
            <h4 className="new-doc-type_meta-title">{translate('newDoc.metaTitle')}:</h4>
            <hr/>
            {this.state.fieldsMeta.map((meta, key) =>
              meta.fieldType.dataType === 'boolean' ?
                <div className="new-doc_meta-section-container" key={key}>
                  {isRecurrent(meta.fieldType.recurrent) ?
                    <Fragment>
                      {meta.fieldValue.map((field, id) =>
                        <Fragment key={id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={event => this.metaFieldCheckBoxHandler(event, id, key)}
                                color="primary"
                                name="value"
                                required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
                              />
                            }
                            label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                          />
                        </Fragment>
                      )}
                      <Button variant="fab" mini color="primary" aria-label="Add"
                              onClick={() => this.add(key, 'boolean')}>+</Button>
                      {meta.fieldValue.length > 1 &&
                      <Button variant="fab" mini color="secondary" aria-label="Delete" style={{right: '30px'}}
                              onClick={() => this.remove(key)}>-</Button>}
                    </Fragment>
                    :
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event => this.metaCheckBoxHandler(event, key)}
                          color="primary"
                          name="required"
                          required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
                        />
                      }
                      label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                    />
                  }
                </div>
                :
                <div className="new-doc_meta-section-container" key={key}>
                  {isRecurrent(meta.fieldType.recurrent) ?
                    <Fragment>
                      {meta.fieldValue.map((field, id) =>
                        <TextField
                          key={id}
                          margin="dense"
                          variant="outlined"
                          name="fieldValue"
                          type={meta.fieldType.dataType}
                          label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                          value={field}
                          onChange={e => this.metaFieldChangeHandler(e, id, key)}
                          required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      <Button variant="fab" mini color="primary" aria-label="Add"
                              onClick={() => this.add(key)}>+</Button>
                      {meta.fieldValue.length > 1 &&
                      <Button variant="fab" mini color="secondary" aria-label="Delete" style={{right: '30px'}}
                              onClick={() => this.remove(key)}>-</Button>}
                    </Fragment>
                    :
                    <TextField
                      margin="dense"
                      variant="outlined"
                      name="fieldValue"
                      type={meta.fieldType.dataType}
                      label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                      value={meta.fieldValue}
                      onChange={e => this.metaChangeHandler(e, key)}
                      required={typeof meta.required === 'string' ? JSON.parse(meta.required) : meta.required}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  }

                </div>
            )}
            <Button variant="contained" color="primary" className="new-doc-type_submit-btn"
                    type="submit">{translate('newDoc.submit')}</Button>
          </form>
        }
      </Fragment>
    );
  }
}

function isRecurrent(recurrent) {
  if (typeof recurrent === 'string') {
    return JSON.parse(recurrent);
  } else {
    return recurrent;
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  docTypes: state.docTypeManagement.docTypes,
  catalogs: state.catalogManagement.catalogs,
  isFetchingDocTypes: state.docTypeManagement.isLoading,
  isFetchingCatalogs: state.catalogManagement.isLoading
});

const mapDispatchToProps = dispatch => ({
  fetchDocTypes: page => dispatch(fetchDocTypes(page)),
  fetchCatalogs: page => dispatch(fetchCatalogs(page)),
  addDoc: (formData, dirID) => dispatch(addDoc(formData, dirID))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDocument);
