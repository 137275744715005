import {NotificationManager} from 'react-notifications';
import axios from '../../axios-api';
import {translate, translateError, translateCombinedError} from "../../localization/i18n";
import {
  SEARCH_BY_WORD, SEARCH_BY_WORD_FAILURE, SEARCH_BY_WORD_SUCCESS,
  SEARCH_CATALOGS, SEARCH_CATALOGS_SUCCESS, SEARCH_CATALOGS_FAILURE,
  SEARCH_DOCUMENT_TYPES, SEARCH_DOCUMENT_TYPES_SUCCESS, SEARCH_DOCUMENT_TYPES_FAILURE,
  SEARCH_DOCS, SEARCH_DOCS_SUCCESS, SEARCH_DOCS_FAILURE, RESET_SEARCH_DOCUMENTS,
  DELETE_DOCUMENT_START, DELETE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILURE
} from "../actionTypes";

const searchRequestHelper = ({url, data}, onInit, onSuccess, onError) => dispatch => {
  dispatch(onInit());

  axios.post(url, data)
    .then(response => {
      if (response.data.status === 1) {
        dispatch(onSuccess(response.data.object.data));
      } else {
        dispatch(onError(response.data));
      }
    }, error => {
      dispatch(onError(error));
    });
};

function searchByWord() {
  return {type: SEARCH_BY_WORD};
}

function searchByWordSuccess(pagesCount, pageSize, result) {
  return {type: SEARCH_BY_WORD_SUCCESS, pagesCount, pageSize, result};
}

function searchByWordFailure(error) {
  return {type: SEARCH_BY_WORD_FAILURE, error};
}

export const searchCatalog = (word, page) =>
  searchRequestHelper(
    {url: "/directory/find", data: {name: word, page: page}},
    searchByWord,
    searchByWordSuccess,
    searchByWordFailure,
  );

export const searchDocType = (word, page) =>
  searchRequestHelper(
    {url: "/documentType/find", data: {name: word, page: page}},
    searchByWord,
    searchByWordSuccess,
    searchByWordFailure,
  );

function searchCatalogs_() {
  return {type: SEARCH_CATALOGS};
}

function searchCatalogsSuccess_(foundCatalogs) {
  return {type: SEARCH_CATALOGS_SUCCESS, foundCatalogs};
}

function searchCatalogsFailure_(error) {
  return {type: SEARCH_CATALOGS_FAILURE, error};
}

export const searchCatalogs = word =>
  searchRequestHelper(
    {url: "/directory/find", data: {name: word, page: 1, pageSize: 50}},
    searchCatalogs_,
    searchCatalogsSuccess_,
    searchCatalogsFailure_,
  );

function searchDocTypes_() {
  return {type: SEARCH_DOCUMENT_TYPES};
}

function searchDocTypesSuccess_(foundDocTypes) {
  return {type: SEARCH_DOCUMENT_TYPES_SUCCESS, foundDocTypes};
}

function searchDocTypesFailure_(error) {
  return {type: SEARCH_DOCUMENT_TYPES_FAILURE, error};
}

export const searchDocTypes = word =>
  searchRequestHelper(
    {url: "/documentType/find", data: {name: word, page: 1, pageSize: 50}},
    searchDocTypes_,
    searchDocTypesSuccess_,
    searchDocTypesFailure_,
  );

const searchDocuments_ = () =>
  ({type: SEARCH_DOCS});

const searchDocumentsSuccess_ = (pagesCount, pageSize, foundDocs) =>
  ({type: SEARCH_DOCS_SUCCESS, pagesCount, pageSize, foundDocs});

const searchDocumentsFailure_ = error =>
  ({type: SEARCH_DOCS_FAILURE, error});

export const searchDocuments = (page, data) => dispatch => {
  dispatch(searchDocuments_());

  axios.post(`/document/search?page=${page}`, data)
    .then(response => {
        if (response.data.status === 1) {
          dispatch(searchDocumentsSuccess_(response.data.object.pagesCount, response.data.object.pageSize, response.data.object.data));
        } else {
          NotificationManager.info(translateCombinedError('search.noMessage', response.data.message));
          dispatch(searchDocumentsFailure_(response.data));
        }
      },
      error => {
        if (error.response) {
          NotificationManager.error(translateCombinedError('search.findFailed', error.response.data.message));
        } else {
          NotificationManager.error(translate('search.findFailed'));
        }
        dispatch(searchDocumentsFailure_(error));
      });
};

const resetSearchDocuments_ = () =>
  ({type: RESET_SEARCH_DOCUMENTS});

export const resetSearchDocuments = () => dispatch => {
  dispatch(resetSearchDocuments_());
};

function deleteDocumentStart() {
  return {type: DELETE_DOCUMENT_START};
}

function deleteDocumentSuccess(id) {
  return {type: DELETE_DOCUMENT_SUCCESS, id};
}

function deleteDocumentFailure() {
  return {type: DELETE_DOCUMENT_FAILURE};
}

export function deleteDocument(dirID, docID, page, data) {
  return dispatch => {
    dispatch(deleteDocumentStart());

    axios.delete(`/directory/${dirID}/document/${docID}`)
      .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success(translate('workWithDocs.successDeleted'));
            dispatch(deleteDocumentSuccess(response.data.object.id));
            dispatch(searchDocuments(page, data));
          }
          else {
            NotificationManager.error(translateError(response.data.message));
            dispatch(deleteDocumentFailure());
          }
        },
        error => {
          if (error.response) {
            NotificationManager.error(translateError(error.response.data.message));
          }
          dispatch(deleteDocumentFailure());
        });
  };
}