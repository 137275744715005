import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import './DataTable.css';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  }
});

const TablePaginationActions = props => {
  const {classes, theme, numPages, curPage} = props;
  const {onFirstPage, onPrevPage, onNextPage, onLastPage} = props;

  return (
    <TableCell className={classes.root} style={{minWidth: '200px', paddingTop: '4px'}}>
      <IconButton
        onClick={onFirstPage}
        disabled={curPage === 1}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
      </IconButton>
      <IconButton
        onClick={onPrevPage}
        disabled={curPage === 1}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
      </IconButton>
      <IconButton
        onClick={onNextPage}
        disabled={curPage >= numPages}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
      </IconButton>
      <IconButton
        onClick={onLastPage}
        disabled={curPage >= numPages}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
      </IconButton>
    </TableCell>
  );
};

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  numPages: PropTypes.number.isRequired,
  curPage: PropTypes.number.isRequired,
  onFirstPage: PropTypes.func.isRequired,
  onLastPage: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPrevPage: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {withTheme: true})(
  TablePaginationActions,
);

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onSortRequest(event, property);
  };

  render() {
    const {header, order, orderBy} = this.props;

    return (
      <TableHead>
        <TableRow>
          {header.map((row, key) => {
            return (
              row.id === null
                ? <TableCell key={key}/>
                : <TableCell
                  key={row.id}
                  numeric={row.numeric}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  header: PropTypes.array.isRequired,
  onSortRequest: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string,
};

EnhancedTableHead.defaultProps = {
  order: 'asc'
};

const makeTableHeader = (header, {onSortRequest, order, orderBy}) => {
  return (
    orderBy !== null ? (
      <EnhancedTableHead
        header={header}
        onSortRequest={onSortRequest}
        order={order}
        orderBy={orderBy}
      />
    ) : (
      <TableHead>
        <TableRow>
          {header.map(({label, numeric = false}) => (<TableCell numeric={numeric}>{label}</TableCell>))}
        </TableRow>
      </TableHead>
    )
  );
};

const getValue = value => typeof value === 'function' ? value() : value;

const makeTableRow = cols => {
  return cols.map(({value, numeric = false, cb}, key) => {
    if (cb === undefined) {
      return <TableCell numeric={numeric} key={key}>{getValue(value)}</TableCell>;
    } else {
      return <TableCell numeric={numeric} key={key} className="table-cell-with-action"
                        onClick={cb}>{getValue(value)}</TableCell>;
    }
  });
};

function desc(a, b, index) {
  if (b.cols[index].value < a.cols[index].value) {
    return -1;
  }
  if (b.cols[index].value > a.cols[index].value) {
    return 1;
  }
  return 0;
}

function getSorting(header, order, orderBy) {
  const index = header.findIndex(({id}) => id === orderBy);

  return order === 'desc' ? (a, b) => desc(a, b, index) : (a, b) => -desc(a, b, index);
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const maybeSort = (header, rows, orderBy, order) => {
  if (orderBy === undefined || orderBy === null) return rows;

  return stableSort(rows, getSorting(header, order, orderBy));
};

const DEFAULT_DATA_TABLE_SIZE = 10;

class DataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      curPage: 1,
      order: props.order,
      orderBy: props.orderBy,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.curPage !== this.state.curPage) {
      this.props.onPageSwitch(this.state.curPage);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.numPages === 0) return;

    if (nextProps.curPage !== this.state.curPage) {
      this.setState({curPage: nextProps.curPage});
      return;
    }

    const {curPage} = this.state;

    if ((curPage > 1) && (nextProps.numPages < curPage)) {
      this.setState({curPage: curPage - 1});
    }
  }

  onFirstPage = () => {
    this.setState({curPage: 1});
  };

  onLastPage = () => {
    this.setState({curPage: this.props.numPages});
  };

  onNextPage = () => {
    this.setState({curPage: this.state.curPage + 1});
  };

  onPrevPage = () => {
    this.setState({curPage: this.state.curPage - 1});
  };

  onSortRequest = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({order, orderBy});
  };

  render() {
    const {classes, numPages, header, rows, rowsPerPage, isLoading} = this.props;
    const {curPage, order, orderBy} = this.state;

    let actualRowsPerPage = rowsPerPage;
    if (isLoading || (curPage === numPages && rowsPerPage <= DEFAULT_DATA_TABLE_SIZE)) {
      actualRowsPerPage = DEFAULT_DATA_TABLE_SIZE;
    }
    const emptyRows = actualRowsPerPage - Math.min(actualRowsPerPage, rows.length);

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" style={{position: 'relative'}}>
            {header.length > 0 && makeTableHeader(header,
              {onSortRequest: this.onSortRequest, orderBy, order})}
            {isLoading ?
              <TableBody>
                <TableRow style={{height: 48 * emptyRows}}>
                  <TableCell colSpan={6}>
                    <CircularProgress className="preLoader table" size={50}/>
                  </TableCell>
                </TableRow>
              </TableBody>
              :
              <TableBody>
                {maybeSort(header, rows, orderBy, order)
                  .slice(0, actualRowsPerPage - emptyRows)
                  .map(row => {
                    return (
                      <TableRow key={row.id}>
                        {makeTableRow(row.cols)}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{height: 48 * emptyRows}}>
                    <TableCell colSpan={6}/>
                  </TableRow>
                )}
              </TableBody>}
            <TableFooter>
              <TableRow>
                <TablePaginationActionsWrapped
                  numPages={numPages}
                  curPage={curPage}
                  onFirstPage={this.onFirstPage}
                  onLastPage={this.onLastPage}
                  onNextPage={this.onNextPage}
                  onPrevPage={this.onPrevPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  onPageSwitch: PropTypes.func.isRequired,
  curPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number,
  header: PropTypes.array,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  isLoading: PropTypes.bool,
};

DataTable.defaultProps = {
  rowsPerPage: DEFAULT_DATA_TABLE_SIZE,
  header: [],
  orderBy: null,
  order: 'asc',
  isLoading: false,
};

export default withStyles(styles)(DataTable);
