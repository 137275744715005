import axios from '../../axios-api';
import {NotificationManager} from 'react-notifications';
import {translate, translateCombinedError} from "../../localization/i18n";

import {
  FETCH_CATALOGS, FETCH_CATALOGS_SUCCESS, FETCH_CATALOGS_FAILURE,
  DELETE_CATALOG, DELETE_CATALOG_SUCCESS, DELETE_CATALOG_FAILURE,
  ADD_CATALOG, ADD_CATALOG_SUCCESS, ADD_CATALOG_FAILURE,
  CHANGE_CATALOG, CHANGE_CATALOG_SUCCESS, CHANGE_CATALOG_FAILURE,
  FETCH_CATALOG, FETCH_CATALOG_SUCCESS, FETCH_CATALOG_FAILURE,
} from "../actionTypes";

function fetchCatalogs_() {
  return {type: FETCH_CATALOGS};
}

function fetchCatalogsSuccess_(pagesCount, pageSize, catalogs) {
  return {type: FETCH_CATALOGS_SUCCESS, pagesCount, pageSize, catalogs};
}

function fetchCatalogsFailure_(error) {
  return {type: FETCH_CATALOGS_FAILURE, error};
}

export function fetchCatalogs(page) {
  return dispatch => {
    dispatch(fetchCatalogs_());

    axios.get(`/directory?page=${page}`)
      .then(response => {
          if (response.data.status === 1) {
            dispatch(fetchCatalogsSuccess_(response.data.object.pagesCount, response.data.object.pageSize, response.data.object.data));
          } else {
            dispatch(fetchCatalogsFailure_(response.data));
          }
        },
        error => {
          if (error.response) {
            NotificationManager.error(translateCombinedError('catalogManagement.fetchFailed', error.response.data.message));
          } else {
            NotificationManager.error(translate('catalogManagement.fetchFailed'));
          }
          dispatch(fetchCatalogsFailure_(error));
        });
  };
}

function deleteCatalog_() {
  return {type: DELETE_CATALOG};
}

function deleteCatalogSuccess_() {
  return {type: DELETE_CATALOG_SUCCESS};
}

function deleteCatalogFailure_(error) {
  return {type: DELETE_CATALOG_FAILURE, error};
}

export function deleteCatalog(id, page, refetchOnDelete = fetchCatalogs) {
  return dispatch => {
    dispatch(deleteCatalog_());

    axios.delete(`/directory/${id}`)
      .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success(translate('catalogManagement.deletedSuccess'));
            dispatch(refetchOnDelete(page));
            dispatch(deleteCatalogSuccess_());
          } else {
            NotificationManager.error(translateCombinedError('catalogManagement.deletedFailure', response.data.message));
            dispatch(deleteCatalogFailure_(response.data));
          }
        },
        error => {
          if (error.response) {
            NotificationManager.error(translateCombinedError('catalogManagement.deletedFailure', error.response.data.message));
          } else {
            NotificationManager.error(translate('catalogManagement.deletedFailure'));
          }
          dispatch(deleteCatalogFailure_(error));
        });
  };
}

function addCatalog_() {
  return {type: ADD_CATALOG};
}

function addCatalogSuccess_() {
  return {type: ADD_CATALOG_SUCCESS};
}

function addCatalogFailure_(error) {
  return {type: ADD_CATALOG_FAILURE, error};
}

export function addCatalog(data) {
  return dispatch => {
    dispatch(addCatalog_());

    axios.post('/directory', data)
      .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success(translate('newCatalog.successMessage'));
            dispatch(addCatalogSuccess_());
          } else {
            NotificationManager.error(translateCombinedError('newCatalog.errorMessage', response.data.message));
            dispatch(addCatalogFailure_(response.data));
          }
        },
        error => {
          if (error.response) {
            NotificationManager.error(translateCombinedError('newCatalog.errorMessage', error.response.data.message));
          } else {
            NotificationManager.error(translate('newCatalog.errorMessage'));
          }
          dispatch(addCatalogFailure_(error));
        });
  };
}

function changeCatalog_() {
  return {type: CHANGE_CATALOG};
}

function changeCatalogSuccess_() {
  return {type: CHANGE_CATALOG_SUCCESS};
}

function changeCatalogFailure_(error) {
  return {type: CHANGE_CATALOG_FAILURE, error};
}

export function changeCatalog(id, data) {
  return dispatch => {
    dispatch(changeCatalog_);

    axios.put(`/directory/${id}`, data)
      .then(response => {
          if (response.data.status === 1) {
            NotificationManager.success(translate('editCatalog.successMessage'));
            dispatch(changeCatalogSuccess_());
          } else {
            NotificationManager.error(translateCombinedError('editCatalog.cantSave', response.data.message));
            dispatch(changeCatalogFailure_(response.data));
          }
        },
        error => {
          if (error.response) {
            NotificationManager.error(translateCombinedError('editCatalog.cantSave', error.response.data.message));
          } else {
            NotificationManager.error(translate('editCatalog.cantSave'));
          }
          dispatch(changeCatalogFailure_(error));
        });
  };
}

function fetchCatalog_() {
  return {type: FETCH_CATALOG};
}

function fetchCatalogSuccess_(catalog) {
  return {type: FETCH_CATALOG_SUCCESS, catalog};
}

function fetchCatalogFailure_(error) {
  return {type: FETCH_CATALOG_FAILURE, error};
}

export function fetchCatalog(id) {
  return dispatch => {
    dispatch(fetchCatalog_());

    axios.get(`/directory/${id}`).then(response => {
        if (response.data.status === 1) {
          dispatch(fetchCatalogSuccess_(response.data.object));
        } else {
          dispatch(fetchCatalogFailure_(response.data));
        }
      },
      error => {
        dispatch(fetchCatalogFailure_(error));
      });
  };
}
