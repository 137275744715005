import React, {Component} from 'react';
import Layout from "./components/Layout/Layout";
import Routes from "./Routes";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {setLanguage} from "./store/actions/localization";
import {logout} from "./store/actions/login";


class App extends Component {
  componentWillMount() {
    this.props.setLanguage('RU_ru');
  }

  render() {
    const {setLanguage, lang, user, logout} = this.props;
    return (
      <Layout setLanguage={setLanguage} lang={lang} user={user} logout={logout}>
        <Routes user={user}/>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  user: state.login.authInfo
});

const mapDispatchToProps = dispatch => ({
  setLanguage: language => dispatch(setLanguage(language)),
  logout: () => dispatch(logout())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
