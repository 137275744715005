// Misc useful utilities

export function maybeParseJSONString(value) {
  if (typeof value === 'string') {
    return JSON.parse(value);
  } else {
    return value;
  }
}

// Calendar and date

export function calendarToDate(string) {
  return string ? new Date(string).toISOString().replace(/\.\d{3}Z/, "") : '';
}

export function dateToCalendar(string) {
  return string ? string.replace(/T\d{2}:\d{2}:\d{2}/, "") : '';
}

// Boxed values

export function makeEmptyValue() {
  return {type: 'empty'};
}

export function makeJustValue(value) {
  if (value === '' || value === undefined) return makeEmptyValue();

  return {type: 'value', value};
}

function isValueType(object) {
  return typeof object === 'object' && object.hasOwnProperty('type');
}

function ensureValue(x) {
  return isValueType(x) ? x : makeJustValue(x);
}

export function makeRangeValue(start, end = makeEmptyValue()) {
  const actualStart = ensureValue(start);
  const actualEnd = ensureValue(end);

  return isEmptyValue(actualStart) && isEmptyValue(actualEnd) ?
    makeEmptyValue() : {type: 'range', start: actualStart, end: actualEnd};
}

export function makeOrUpdateStartRangeValue(object) {
  return function (start) {
    return isRangeValue(object) ? makeRangeValue(start, object.end) : makeRangeValue(start);
  };
}

export function makeOrUpdateEndRangeValue(object) {
  return function (end) {
    return isRangeValue(object) ? makeRangeValue(object.start, end) : makeRangeValue(makeEmptyValue(), end);
  };
}

export function isEmptyValue(object) {
  return isValueType(object) && object.type === 'empty';
}

export function isJustValue(object) {
  return isValueType(object) && object.type === 'value';
}

export function isRangeValue(object) {
  return isValueType(object) && object.type === 'range';
}

function justOrEmptyValue(object) {
  return (
    isJustValue(object) ?
      object.value : isEmptyValue(object) ?
      '' : undefined
  );
}

export function startOrJustOrEmptyValue(object) {
  return (
    isRangeValue(object) ?
      justOrEmptyValue(object.start) : isJustValue(object) ?
      object.value : isEmptyValue(object) ?
        '' : undefined
  );
}

export function endOrJustOrEmptyValue(object) {
  return (
    isRangeValue(object) ?
      justOrEmptyValue(object.end) : isJustValue(object) ?
      object.value : isEmptyValue(object) ?
        '' : undefined
  );
}

export function isContainsCyrillic(string) {
  return /[а-яА-ЯЁё]/.test(string);
}