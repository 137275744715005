import {
  AUTH_LOGIN, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE, AUTH_LOGOUT, UPDATE_AUTH_STATE, UPDATE_TOKEN_FAILURE,
} from "../actionTypes";

const initialState = {
  authInfo: null,
  isLogining: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {...state, isLogining: true, error: null, authInfo: null};
    case AUTH_LOGIN_SUCCESS:
      return {...state, isLogining: false, authInfo: action.authInfo};
    case AUTH_LOGIN_FAILURE :
      return {...state, isLogining: false, error: action.error};
    case AUTH_LOGOUT:
      return {...state, authInfo: null};
    case UPDATE_AUTH_STATE:
      return {...state, authInfo: action.userInfo};
    case UPDATE_TOKEN_FAILURE:
      return {...state, authInfo: null};
    default:
      return state;
  }
};

export default reducer;
