import React, {Component, Fragment} from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";
import {translate} from "../../localization/i18n";
import {Button, TextField, FormControl, InputLabel, MenuItem, Select, OutlinedInput, FormControlLabel, Checkbox} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import {NotificationManager} from 'react-notifications';
import cloneDeep from 'lodash/cloneDeep';
import './NewDocumentsType.css';
import {addDocType} from "../../store/actions/docTypeManagement";
import {isContainsCyrillic} from "../../components/Utils";
import { GetApp, Publish } from '@material-ui/icons';

const metaTypes = [{label: 'Дата', value: 'date'}, {label: 'Текст', value: 'text'},
  {label: 'Число', value: 'number'}, {label: 'Булевое значение', value: 'boolean'}];

class NewDocumentsType extends Component {
  state = {
    kkName: '',
    ruName: '',
    enName: '',
    kkDescription: '',
    ruDescription: '',
    enDescription: '',
    fieldsMeta: []
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang) {
      this.forceUpdate();
    }
  }

  submitFormHandler = event => {
    event.preventDefault();
    const data = cloneDeep(this.state);
    const isFieldTypes = data.fieldsMeta.every(meta => meta.fieldType.dataType);
    if (!isFieldTypes) {
      return NotificationManager.error(translate('newDocType.fillTypes'));
    } else {
      delete data.meta;
      this.props.addDocType(data);
    }
  };

  inputChangeHandler = event => {
    switch (event.target.name) {
      case 'enName': case 'enDescription':
        if (!isContainsCyrillic(event.target.value)) {
          this.setState({[event.target.name]: event.target.value});
        }
        break;
      default:
        this.setState({[event.target.name]: event.target.value});
    }
  };

  addMeta = () => {
    const fieldsMeta = [...this.state.fieldsMeta];
    const section = {
      kkFieldName: '',
      ruFieldName: '',
      enFieldName: '',
      fieldCode: `0${fieldsMeta.length > 0 ? 
        JSON.parse(fieldsMeta[fieldsMeta.length - 1].fieldCode.slice(1)) + 1 : 1}`,
      fieldType: {
        dataType: '',
        recurrent: false
      },
      required: false
    };
    fieldsMeta.push(section);
    this.setState({fieldsMeta});
  };

  sectionChangeHandler = (event, id) => {
    const fieldsMeta = [...this.state.fieldsMeta];
    const section = fieldsMeta[id];
    section[event.target.name] = event.target.value;
    this.setState({fieldsMeta});
  };

  sectionFieldTypeChangeHandler = (event, id) => {
    const fieldsMeta = cloneDeep(this.state.fieldsMeta);
    const section = fieldsMeta[id];
    if (event.target.name === 'recurrent') {
      section.fieldType.recurrent = !section.fieldType.recurrent;
      this.setState({fieldsMeta});
    } else {
      section.fieldType.dataType = event.target.value;
      this.setState({fieldsMeta});
    }
  };

  sectionCheckBoxHandler = (event, id) => {
    const fieldsMeta = [...this.state.fieldsMeta];
    const section = fieldsMeta[id];
    section[event.target.name] = !section[event.target.name];
    this.setState({fieldsMeta});
  };

  removeMeta = id => {
    const fieldsMeta = [...this.state.fieldsMeta];
    fieldsMeta.splice(id, 1);
    this.setState({fieldsMeta});
  };

  toTop = id => {
    const fieldsMeta = [...this.state.fieldsMeta];
    const itemToTop = fieldsMeta[id];
    const itemToBottom = fieldsMeta[id - 1];
    fieldsMeta[id - 1] = itemToTop;
    fieldsMeta[id] = itemToBottom;
    this.setState({fieldsMeta});
  };

  toBottom = id => {
    const fieldsMeta = [...this.state.fieldsMeta];
    const itemToBottom = fieldsMeta[id];
    const itemToTop = fieldsMeta[id + 1];
    fieldsMeta[id] = itemToTop;
    fieldsMeta[id + 1] = itemToBottom;
    this.setState({fieldsMeta});
  };

  render() {
    const { fieldsMeta } = this.state;
    return (
      <Fragment>
        <h3>{translate('newDocType.title')}</h3>
        <form onSubmit={this.submitFormHandler} autoComplete="off">
          <div className="new-doc-type_form-container">
            <TextField
              label={translate('newDocType.kkName')}
              margin="dense"
              variant="outlined"
              name="kkName"
              value={this.state.kkName}
              onChange={this.inputChangeHandler}
              fullWidth
              required
            />
            <TextField
              label={translate('newDocType.ruName')}
              margin="dense"
              variant="outlined"
              name="ruName"
              value={this.state.ruName}
              onChange={this.inputChangeHandler}
              fullWidth
              required
            />
            <TextField
              label={translate('newDocType.enName')}
              margin="dense"
              variant="outlined"
              name="enName"
              value={this.state.enName}
              onChange={this.inputChangeHandler}
              fullWidth
            />
            <TextField
              label={translate('newDocType.kkDescription')}
              multiline
              rows="3"
              margin="dense"
              variant="outlined"
              name="kkDescription"
              value={this.state.kkDescription}
              onChange={this.inputChangeHandler}
              fullWidth
              required
            />
            <TextField
              label={translate('newDocType.ruDescription')}
              multiline
              rows="3"
              margin="dense"
              variant="outlined"
              name="ruDescription"
              value={this.state.ruDescription}
              onChange={this.inputChangeHandler}
              fullWidth
              required
            />
            <TextField
              label={translate('newDocType.enDescription')}
              multiline
              rows="3"
              margin="dense"
              variant="outlined"
              name="enDescription"
              value={this.state.enDescription}
              onChange={this.inputChangeHandler}
              fullWidth
            />
            <Button variant="contained" color="primary" type="submit"
                    className="new-doc-type_submit-btn">{translate('newDocType.create')}</Button>
          </div>
          <h4 className="meta-title">{translate('newDocType.metaTitle')}:</h4>
          <hr/>
          <div className="meta-add_container">
            <h5>{translate('newDocType.addMeta')}</h5>
            <Button variant="fab" mini color="primary" aria-label="Add" style={{fontSize: '20px'}}
                    onClick={this.addMeta}>+</Button>
          </div>
          {fieldsMeta.map((section, id) =>
            <div key={id} className="meta-section">
              <TextField
                label={translate('newDocType.kkName')}
                margin="dense"
                variant="outlined"
                name="kkFieldName"
                value={section.kkFieldName}
                onChange={e => this.sectionChangeHandler(e, id)}
                InputLabelProps={{
                  shrink: true
                }}
                required
              />
              <TextField
                label={translate('newDocType.ruName')}
                margin="dense"
                variant="outlined"
                name="ruFieldName"
                value={section.ruFieldName}
                onChange={e => this.sectionChangeHandler(e, id)}
                InputLabelProps={{
                  shrink: true
                }}
                required
              />
              <TextField
                label={translate('newDocType.enName')}
                margin="dense"
                variant="outlined"
                name="enFieldName"
                value={section.enFieldName}
                onChange={e => this.sectionChangeHandler(e, id)}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                label={translate('newDocType.code')}
                margin="dense"
                variant="outlined"
                name="fieldCode"
                value={section.fieldCode}
                onChange={e => this.sectionChangeHandler(e, id)}
                InputLabelProps={{
                  shrink: true
                }}
                required
              />
              <FormControl variant="outlined" margin="dense" required>
                <InputLabel
                  ref={ref => {
                    this.labelRef = ReactDOM.findDOMNode(ref);
                  }}
                  shrink={true}
                  htmlFor="age-required"
                  style={{background: '#fff'}}
                >
                  {translate('newDocType.type')}
                </InputLabel>
                <Select
                  value={section.fieldType.dataType}
                  onChange={e => this.sectionFieldTypeChangeHandler(e, id)}
                  name="dataType"
                  inputProps={{
                    id: 'age-required'
                  }}
                  input={
                    <OutlinedInput
                      labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
                      name="age"
                      id="age-required"
                    />
                  }
                >
                  {metaTypes.map((type, key) =>
                    <MenuItem key={key} value={type.value}>{type.label}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <div className="meta-section_checkbox-container">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={section.required}
                      onChange={e => this.sectionCheckBoxHandler(e, id)}
                      color="primary"
                      name="required"
                    />
                  }
                  label={translate('newDocType.required')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={section.fieldType.recurrent}
                      onChange={e => this.sectionFieldTypeChangeHandler(e, id)}
                      color="primary"
                      name="recurrent"
                    />
                  }
                  label={translate('newDocType.recurrent')}
                />
              </div>
              <Button color="secondary" onClick={() => this.removeMeta(id)}><DeleteIcon/></Button>
              <div className="meta-section_sort">
                <Button
                  onClick={() => this.toTop(id)}
                  disabled={id === 0}
                >
                  <Publish/>
                </Button>
                <Button
                  onClick={() => this.toBottom(id)}
                  disabled={(id === (fieldsMeta.length - 1)) || fieldsMeta.length === 1}
                >
                  <GetApp/>
                </Button>
              </div>
            </div>)}
        </form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language
});

const mapDispatchToProps = dispatch => ({
  addDocType: data => dispatch(addDocType(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDocumentsType);