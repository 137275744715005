import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {translate} from "../../localization/i18n";
import {
  TextField,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress
} from '@material-ui/core';
import ViewBtn from '@material-ui/icons/Visibility';
import DownloadBtn from '@material-ui/icons/CloudDownload';
import {fetchCatalog, fetchCatalogs} from "../../store/actions/catalogManagement";
import {fetchDocType, fetchDocTypes} from "../../store/actions/docTypeManagement";
import {addDoc, cleanDoc, fetchDocument} from "../../store/actions/workWithDocs";
import {dateToCalendar} from "../../components/Utils";
import './ViewDocument.css';
import DynamicDataTable from "../../components/DynamicDataTable/DynamicDataTable";
import axios from '../../axios-api';
import fileType from'file-type';
import {NotificationManager} from 'react-notifications';

class ViewDocument extends Component {
  state = {
    catalog: '',
    documentType: '',
    fieldsMeta: [],
    documentVersions: null,
    versionsIsLoading: true,
    docVersion: {},
    viewing: false,
    downloading: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lang !== this.props.lang ||
      (prevState.documentType && prevState.documentType !== this.state.documentType)) {
      this.forceUpdate();
    }

    const {catalog, documentType} = this.props;

    if ((!this.state.catalog && catalog.id) || (this.state.catalog.value !== catalog.id)) {
      this.setState({catalog: {kkName: catalog.kkName, ruName: catalog.ruName, value: catalog.id}});
    }

    if ((!this.state.documentType && documentType.id) || (this.state.documentType.value !== documentType.id)) {
      const fieldsMeta = documentType.fieldsMeta.map(meta => {
        if (isRecurrent(meta.fieldType.recurrent)) {
          return {...meta, fieldValue: [{value: ''}]};
        } else {
          return {...meta, fieldValue: ''};
        }
      });
      this.setState({
        documentType: {
          kkName: documentType.kkName,
          ruName: documentType.ruName,
          value: documentType.id
        },
        fieldsMeta
      });
    }

    const document = this.props.document;

    if (this.state.docVersion.id !== prevState.docVersion.id) {
      if (this.state.docVersion.documentType !== documentType.id) {
        this.props.getDocumentType(this.state.docVersion.documentType);
      }

      if (this.state.docVersion.directoryId !== catalog.id) {
        this.props.getCatalog(this.state.docVersion.directoryId);
      }
    }

    if (document.id && !this.state.documentVersions) {
      if (document.version > 1) {
        const promises = [];
        for (let i = 1; i <= document.version; i++) {
          if (document.version !== i) {
            promises.push(new Promise(function (resolve, reject) {
              axios.get(`/directory/${document.directoryId}/document/${document.id}/version/${i}`)
                .then(response => {
                  if (response.data.status === 1) {
                    resolve(response.data.object);
                  } else {
                    reject(response.data);
                  }
                })
                .catch(error => {
                  reject(error);
                });
            }));
          }
        }
        Promise.all(promises).then(result => {
          const documentVersions = result;
          documentVersions.push(document);
          this.setState({documentVersions, versionsIsLoading: false});
        }).catch(() => {
          this.setState({versionsIsLoading: false});
        });
      } else if (document.version === 1 && this.state.versionsIsLoading) {
        this.setState({versionsIsLoading: false});
      }
    }
  }

  componentWillUnmount() {
    this.props.cleanDoc();
  }

  componentDidMount() {
    const {dirID, typeID, id} = this.props.match.params;
    this.props.getDocument(dirID, id);
    this.props.getDocumentType(typeID);
    this.props.getCatalog(dirID);
  }

  view = (doc) => {
    NotificationManager.info(translate('viewDoc.viewStart'));
    this.setState({viewing: true});
    axios.get(`/directory/${doc.directoryId}/document/${doc.id}/file?action=view${this.props.document.version !== doc.version ? '&documentVersion=' + doc.version : ''}`, {
      responseType: 'arraybuffer'
    }).then((response) => {
      const dataType = fileType(new Uint8Array(response.data));
      const type = dataType ? dataType.mime : "application/octet-binary";
      const url = window.URL.createObjectURL(new Blob([response.data], {type}));

      this.setState({viewing: false});
      window.open(url, "_blank")
    }, error => {
      console.log(error);
      NotificationManager.error(translate('viewDoc.viewFailed'));
      this.setState({viewing: false});
    });
  };

  download = (doc) => {
    NotificationManager.info(translate('viewDoc.downloadStart'));
    this.setState({downloading: true});
    axios.get(`/directory/${doc.directoryId}/document/${doc.id}/file?action=download${this.props.document.version !== doc.version ? '&documentVersion=' + doc.version : ''}`, {
      responseType: 'blob'
    }).then((response) => {
      this.setState({downloading: false});
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', doc.fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, error => {
      console.log(error);
      NotificationManager.error(translate('viewDoc.downloadFailed'));
      this.setState({downloading: false});
    });
  };

  viewDocVersion = docVersion => {
    this.setState({docVersion});
  };

  render() {
    const {lang, document, documentType, catalog} = this.props;
    const {isDocLoading, isDocTypeLoading, isCatalogLoading} = this.props;
    const {versionsIsLoading, documentVersions, docVersion, viewing, downloading} = this.state;
    let metaLang, typeLang, isLoading, metas, data, doc;
    if (lang === 'RU_ru') {
      metaLang = 'ruFieldName';
      typeLang = 'ruName';
    } else {
      metaLang = 'kkFieldName';
      typeLang = 'kkName';
    }
    isLoading = isDocLoading || isDocTypeLoading || isCatalogLoading;
    metas = [];
    data = docVersion.data || document.data;
    doc = docVersion.id ? docVersion : document;
    if (data && documentType.fieldsMeta) {
      data.forEach(doc => {
        documentType.fieldsMeta.forEach(type => {
          if (type.fieldCode === doc.fieldCode) {
            const obj = {...type};
            obj.fieldValue = doc.fieldValue;
            metas.push(obj);
          }
        });
      });
    }

    return (
      <Fragment>
        <h3>{translate('viewDoc.title')}</h3>
        {isLoading ? <LinearProgress className="preLoader"/> :
          <form className="new-doc-type_form-container" onSubmit={this.submitFormHandler} autoComplete="off">
            <TextField
              label={translate('viewDoc.catalog')}
              margin="dense"
              variant="outlined"
              value={catalog[typeLang]}
              InputLabelProps={{shrink: true}}
              fullWidth
              InputProps={{readOnly: true}}
            />
            <TextField
              label={translate('viewDoc.docType')}
              margin="dense"
              variant="outlined"
              value={documentType[typeLang]}
              fullWidth
              InputLabelProps={{shrink: true}}
              InputProps={{readOnly: true}}
            />
            <TextField
              label={translate('viewDoc.docVersion')}
              margin="dense"
              variant="outlined"
              value={doc.id ? `${doc.version} | ${dateToCalendar(doc.updatedDate)}` : ''}
              fullWidth
              InputLabelProps={{shrink: true}}
              InputProps={{readOnly: true}}
            />
            <TextField
              label={translate('viewDoc.operator')}
              margin="dense"
              variant="outlined"
              value={doc.updatedBy}
              fullWidth
              InputLabelProps={{shrink: true}}
              InputProps={{readOnly: true}}
            />
            <TextField
              label={translate('newDoc.edc')}
              margin="dense"
              variant="outlined"
              value={doc.signature}
              fullWidth
              rows="4"
              InputLabelProps={{shrink: true}}
              InputProps={{readOnly: true}}
              multiline
            />
            <div className="new-doc_upload-section">
              <TextField
                label={translate('viewDoc.uploadedFile')}
                margin="dense"
                variant="outlined"
                value={doc.fileName}
                InputLabelProps={{shrink: true}}
                InputProps={{readOnly: true}}
                fullWidth
              />
              <label>
                <Button disabled={viewing} variant="contained" component="span"
                        onClick={() => this.view(doc)}>
                  {viewing ? <CircularProgress size={20}/> : <ViewBtn/>}
                </Button>
              </label>
              <label>
                <Button disabled={downloading} variant="contained" component="span"
                        onClick={() => this.download(doc)}>
                  {downloading ? <CircularProgress size={20}/> : <DownloadBtn/>}
                </Button>
              </label>
            </div>
            <h4 className="new-doc-type_meta-title">{translate('newDoc.metaTitle')}:</h4>
            <hr/>
            {metas.map((meta, key) =>
              meta.fieldType.dataType === 'boolean' ?
                <div className="new-doc_meta-section-container" key={key}>
                  {isRecurrent(meta.fieldType.recurrent) ?
                    <Fragment>
                      {(meta.fieldValue.length === 0 ? [''] : meta.fieldValue).map((field, id) =>
                        <Fragment key={id}>
                          <FormControlLabel
                            disabled
                            checked={field}
                            control={
                              <Checkbox
                                color="primary"
                                name="value"
                              />
                            }
                            label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                          />
                        </Fragment>
                      )}
                    </Fragment>
                    :
                    <FormControlLabel
                      disabled
                      checked={meta.fieldValue}
                      control={
                        <Checkbox
                          color="primary"
                          name="required"
                        />
                      }
                      label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                    />
                  }
                </div>
                :
                <div className="new-doc_meta-section-container" key={key}>
                  {isRecurrent(meta.fieldType.recurrent) ?
                    <Fragment>
                      {(meta.fieldValue.length === 0 ? [''] : meta.fieldValue).map((field, id) =>
                        <TextField
                          key={id}
                          margin="dense"
                          variant="outlined"
                          type={meta.fieldType.dataType}
                          label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                          value={meta.fieldType.dataType === 'date' ? dateToCalendar(field) : field}
                          fullWidth
                          InputLabelProps={{shrink: true}}
                          InputProps={{readOnly: true}}
                        />
                      )}
                    </Fragment>
                    :
                    <TextField
                      margin="dense"
                      variant="outlined"
                      type={meta.fieldType.dataType}
                      label={`${meta[metaLang]}${meta.enFieldName ? `/${meta.enFieldName}` : ''}`}
                      value={meta.fieldType.dataType === 'date' ? dateToCalendar(meta.fieldValue) : meta.fieldValue}
                      fullWidth
                      InputLabelProps={{shrink: true}}
                      InputProps={{readOnly: true}}
                    />
                  }
                </div>
            )}
            <h4 className="new-doc-type_meta-title">{translate('viewDoc.versionHistory')}</h4>
            <hr/>
            <DynamicDataTable
              header={[
                {id: "filename", label: translate('viewDoc.colFilename')},
                {id: "dateVer", label: translate('viewDoc.colDateVer')},
                {id: "numVer", label: translate('viewDoc.colNumVer')},
                {id: "operator", label: translate('viewDoc.colOperator')}
              ]}
              rows={(documentVersions || []).map(doc => ({
                id: doc.version,
                cols: [
                  {value: doc.fileName, cb: () => this.viewDocVersion(doc)},
                  {value: dateToCalendar(doc.updatedDate)},
                  {value: doc.version},
                  {value: doc.updatedBy}
                ]
              }))}
              isLoading={versionsIsLoading}
              orderBy='numVer'/>
          </form>
        }
      </Fragment>
    );
  }
}

function isRecurrent(recurrent) {
  if (typeof recurrent === 'string') {
    return JSON.parse(recurrent);
  } else {
    return recurrent;
  }
}

const mapStateToProps = state => ({
  lang: state.localization.language.language,
  docTypes: state.docTypeManagement.docTypes,
  catalogs: state.catalogManagement.catalogs,
  isFetchingDocTypes: state.docTypeManagement.isLoading,
  isFetchingCatalogs: state.catalogManagement.isLoading,
  document: state.workWithDocs.document,
  documentType: state.docTypeManagement.docType,
  catalog: state.catalogManagement.catalog,
  isDocLoading: state.workWithDocs.isLoading,
  isDocTypeLoading: state.docTypeManagement.isLoading,
  isCatalogLoading: state.catalogManagement.isLoading
});

const mapDispatchToProps = dispatch => ({
  fetchDocTypes: page => dispatch(fetchDocTypes(page)),
  fetchCatalogs: page => dispatch(fetchCatalogs(page)),
  addDoc: (formData, dirID) => dispatch(addDoc(formData, dirID)),
  getDocument: (dirID, docID) => dispatch(fetchDocument(dirID, docID)),
  getDocumentType: id => dispatch(fetchDocType(id)),
  getCatalog: id => dispatch(fetchCatalog(id)),
  cleanDoc: () => dispatch(cleanDoc())
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDocument);
