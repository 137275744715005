import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, TextField, LinearProgress} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import './SimpleSearchBox.css';

function renderInput(inputProps) {
  const {classes, placeholder, ref, onChange, ...other} = inputProps;

  return (
    <TextField
      placeholder={placeholder}
      onChange={onChange}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
        },
      }}
      {...other}
      fullWidth
    />
  );
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function SimpleSearchBox(props) {
  const {classes, placeholder, selectChangeHandler, className, isLoading, icon} = props;

  return (
    <div className={className}>
      <SearchIcon style={{position: 'relative', top: '7px', left: '-2px', color: 'grey', display: icon}}/>
      <div
        className={classes.container}>
        {renderInput({
          classes,
          placeholder,
          onChange: selectChangeHandler,
        })}
        <LinearProgress
          className="simplesearchbox-progressbar"
          style={{display: isLoading ? 'block' : 'none'}}
        />
      </div>
      <div className={classes.divider}/>
    </div>
  );
}

SimpleSearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectChangeHandler: PropTypes.func.isRequired,
};

export default withStyles(styles)(SimpleSearchBox);
