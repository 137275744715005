export const RU_ru = {
  translation: {
    login: {
      title: 'Авторизация',
      inputLogin: 'Логин',
      inputPassword: 'Пароль',
      button: 'Войти',
      successMsg: 'Авторизация прошла успешно',
      errorMsg: 'Ошибка авторизации',
      logout: 'Выход',
      updateToken: 'Обновляем токен',
      tokenFetched: 'Токен обновлён. Можете продолжить работу',
      updateTokenFailure: 'Не удалось обновить токен, попробуйте зайти заново'
    },

    layout: {
      catalogManagement: 'Управление каталогами',
      docTypeManagement: 'Управление типами документов',
      workWithDocs: 'Работа с документами',
      search: 'Поиск'
    },

    catalogManagement: {
      button: 'Новый каталог',
      title: 'Управление каталогами',
      edit: 'редактировать',
      delete: 'удалить',
      start: 'в начало',
      previous: 'предыдущий',
      next: 'следующий',
      end: 'в конец',
      searchBoxPlaceholder: 'Поиск каталога',
      fetchFailed: 'Не удалось получить каталоги',
      deletedSuccess: 'Каталог удален!',
      deletedFailure: 'Не удалось удалить каталог',
      confirm: 'Подтвердите действие',
      deleteConfirm: 'Удалить',
      cancelConfirm: 'Отменить',
    },

    newCatalog: {
      title: 'Управление каталогами / Новый каталог',
      kkName: 'Наименование на казахском',
      ruName: 'Наименование на русском',
      enName: 'Наименование на английском',
      kkDescription: 'Описание на казахском',
      ruDescription: 'Описание на русском',
      enDescription: 'Описание на английском',
      create: 'Создать',
      successMessage: 'Новый каталог создан',
      errorMessage: 'Не удалось создать новый каталог'
    },

    editCatalog: {
      title: 'Редактировать каталог',
      errorMessage: 'Не удалось изменить каталог',
      saveChanges: 'Сохранить изменения',
      successMessage: 'Изменения сохранены',
      cantSave: 'Не удалось сохранить изменения',
    },

    viewCatalog: {
      title: 'Просмотр каталога'
    },

    documentTypeManagement: {
      button: 'Новый тип документа',
      title: 'Управление типами документов',
      edit: 'редактировать',
      delete: 'удалить',
      start: 'в начало',
      previous: 'предыдущий',
      next: 'следующий',
      end: 'в конец',
      searchBoxPlaceholder: 'Поиск типа документа',
      fetchFailed: 'Не удалось получить типы документов',
      deletedSuccess: 'Тип документа удален!',
      deletedFailure: 'Не удалось удалить тип документа',
      confirm: 'Подтвердите действие',
      deleteConfirm: 'Удалить',
      cancelConfirm: 'Отменить',
    },

    newDocType: {
      title: 'Управление типами документов / Новый тип документа',
      kkName: 'Наименование на казахском',
      ruName: 'Наименование на русском',
      enName: 'Наименование на английском',
      kkDescription: 'Описание на казахском',
      ruDescription: 'Описание на русском',
      enDescription: 'Описание на английском',
      create: 'Создать',
      metaTitle: 'Метаданные типа',
      addMeta: 'Добавить секцию метаданных',
      code: 'Идентификатор поля',
      type: 'Тип поля',
      required: 'Обязательность поля',
      fillTypes: 'Выберите тип для всех метаданных',
      successMessage: 'Новый тип добавлен',
      errorMessage: 'Не удалось добавить тип',
      recurrent: 'Массив полей'
    },

    workWithDocs: {
      title: 'Работа с документами',
      button: 'Новый документ',
      searchByName: 'Поиск по имени файла',
      catalog: 'Каталог',
      docType: 'Тип документа',
      noMessage: 'Ничего не найдено',
      colFilename: 'Имя файла',
      colDateVer: 'Дата версии',
      colCatalog: 'Каталог',
      colDocType: 'Тип документа',
      edit: 'редактировать',
      delete: 'удалить',
      successDeleted: 'Удалено',
      naPlaceholder: '',
    },

    newDoc: {
      title: 'Работа с документами / Новый документ',
      docType: 'Поиск и выбор типа документа',
      edc: 'ЭЦП',
      upload: 'Загрузить документ',
      metaTitle: 'Метаданные документа',
      submit: 'Создать',
      chooseTypeError: 'Выберите тип документа',
      chooseCatalogError: 'Выберите каталог',
      successMessage: 'Документ добавлен',
      errorMessage: 'Не удалось добавить документ',
      catalog: 'Поиск и выбор каталога',
    },

    viewDoc: {
      title: 'Работа с документами / Просмотр документа',
      catalog: 'Каталог',
      docType: 'Тип документа',
      docVersion: 'Версия документа',
      operator: 'Оператор',
      edc: 'ЭЦП',
      uploadedFile: 'Загруженный файл',
      editButton: 'Редактировать',
      deleteButton: 'Удалить',
      versionHistory: 'История версий',
      colFilename: 'Имя файла',
      colDateVer: 'Дата версии',
      colNumVer: 'Номер версии',
      colOperator: 'Оператор',
      viewFailed: 'Не удалось просмотреть документ',
      viewStart: 'Готовим файл для просмотра',
      downloadFailed: 'Не удалось скачать документ',
      downloadStart: 'Готовим файл для скачивания',
    },

    search: {
      title: 'Поиск',
      catalog: 'Каталог',
      docType: 'Тип документа',
      filename: 'Имя файла',
      dateFrom: 'Дата начала',
      dateTo: 'Дата конца',
      find: 'Найти',
      metaTitle: 'Метаданные документа',
      exactCheckbox: 'Полное совпадение',
      resultsTitle: 'Результаты поиска',
      findFailed: 'Не удалось отправить данные поиска',
      noMessage: 'Ничего не найдено',
      requiredData: 'Каталог и тип документа должны быть заданы',
      colFilename: 'Имя файла',
      colCatalog: 'Каталог',
      colDocType: 'Тип документа',
      edit: 'редактировать',
      delete: 'удалить',
      constraint: "Ограничение",
      op: {
        GREATER_OR_EQUALS: "Больше или равно",
        LESS_OR_EQUALS: "Меньше или равно",
        GREATER: "Больше",
        LESS: "Меньше",
        EXACT: "Точное совпадение",
        BETWEEN: "Между",
        CONTAINS: "Содержит",
      }
    },

    editDocType: {
      title: 'Редактировать тип документа',
      errorMessage: 'Не удалось загрузить тип документа',
      saveChanges: 'Сохранить изменения',
      successMessage: 'Изменения сохранены',
      cantSave: 'Не удалось сохранить изменения'
    },

    viewDocType: {
      title: 'Просмотр типа документа'
    },

    editDoc: {
      subscribe: 'Подписать',
      saveChanges: 'Сохранить изменения',
      title: 'Редактировать документ',
      ncalayerError: 'Программа NCALayer не запущена',
      subscribeCanceled: 'Подпись документа отменена',
      cantGetXml: 'Не удалось получить XML'
    },

    errors: {
      INCORRECT_USERNAME_PASSWORD: "Неправильный логин или пароль.",
      TOKEN_HAS_EXPIRED: "Токен для доступа просрочен.",
      REQUIRED_FIELD_IS_EMPTY: "Необходимо заполнить все обязательные поля объекта.",
      DIRECTORY_NOT_FOUND: "Директория не найдена/не существует.",
      DIRECTORY_CANNOT_DELETE_OBJECTS_EXIST: "Нельзя удалить директорию, к ней привязаны документы.",
      DIRECTORY_DUPLICATE: "В системе уже существует такая директория.",
      CANNOT_CREATE_DIRECTORY: "Не удается создать директорию (нет прав на создание каталогов).",
      DOCUMENT_TYPE_NOT_FOUND: "Тип документа не найден/не существует.",
      DOCUMENT_TYPE_MISMATCHES_DATA_TYPE: "Документ не соответствует типу документа.",
      DOCUMENT_DATA_MISMATCHES_DATA_TYPE: "Данные документа не соответствуют типу данных.",
      DOCUMENT_TYPE_CANNOT_DELETE_OBJECTS_EXIST: "Нельзя удалить тип документа, к нему привязаны документы.",
      DOCUMENT_TYPE_INVALID_OPERATION_FIELD_DELETE: "Нельзя удалить поле, имеются документы данного типа с заполненными полями.",
      DOCUMENT_TYPE_INVALID_OPERATION_FIELD_REQUIRED_CHANGE: "Нельзя изменить обязательность поля, имеются документы данного типа с незаполненными полями.",
      DOCUMENT_TYPE_INVALID_OPERATION_FIELD_DATA_TYPE_CHANGE: "Нельзя изменить тип данных поля, имеются докмуенты данного типа с заполненными полями.",
      DOCUMENT_TYPE_META_DATA_DUPLICATE: "Мета поля типа данных дублируются.",
      DOCUMENT_NOT_FOUND: "Документ не найден/не существует.",
      WRONG_DATE_FORMAT: "Неверный формат даты.",
      DOCUMENT_DATA_MISMATCH_DATA_TYPE: "Неверный тип данных в одном из полей документа.",
      DOCUMENT_WRONG_SIGNATURE: "Неверная подпись.",
      DOCUMENT_VERSION_DOES_NOT_EXIST: "Документ с данной версией не существует.",
      DOCUMENT_TYPE_DUPLICATE: "В системе  уже существует такой тип документа.",
      FILE_MUST_BE_PRESENT: "Необходимо загрузить файл.",
      FILE_READ_EXCEPTION: "Не удается считать/найти файл.",
      FILE_SAVE_EXCEPTION: "Ошибка сохранения файла.",
      FILE_DOES_NOT_EXIST: "Файл не существует.",
      NULL_SIGNATURE_NODE: "Отсутствует подпись.",
      CANT_FIND_CERTIFICATE: "Не удается найти подходящий сертификат.",
      KEY_NOT_FOUND: "Ключ не найден.",
      ILLEGAL_CERTIFICATE: "Неверный сертификат.",
      ILLEGAL_IIN_SIZE: "Неверный размер ИИН.",
      COULD_NOT_FIND_CERTIFICATE_IIN: "Не удается найти ИИН сертифката.",
      VERIFICATION_FAILED: "Не удается верифицировать + текст ошибки.",
      UNKNOWN_ERROR: "Неизвестная ошибка.",
      UNHANDLED_EXCEPTION: "Что-то пошло не так.",
      ACTION_NOT_ALLOWED: 'Действие не допускается.',
      AUTH_SERVICE_IS_UNAVAILABLE: 'Сервис авторизации не доступен.',
    },

    routeError: {
      noPage: 'Такой страницы не существует'
    },

    docTypes: {
      date: "Дата",
      number: "Число",
      text: "Текст",
      boolean: "Булево значение",
    }
  }
};
